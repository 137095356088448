import React, { useState } from 'react';
import { useNavigate, Link, useLocation, useSearchParams } from 'react-router-dom';
import { Music2, Users, Send, Check, ImageIcon, User, Star } from 'lucide-react';
import { hasSubmittedToPlaylist } from '../utils/submissionTracking';
import { getLastSubmissionData } from '../utils/storage';
import { scrollManager } from '../utils/scrollManager';

interface PlaylistCardProps {
  playlist: {
    id: string;
    name: string;
    description: string;
    image_url: string;
    spotify_id: string;
    followers: number;
    featured?: boolean;
    genre: string;
    genre_slug: string;
    curator_name?: string | null;
    curator_profile_url?: string | null;
    sub_genres: string[];
    slug: string;
  };
  onSubmit: (id: string) => void;
  isRelated?: boolean;
  priority?: boolean;
  hideSubmit?: boolean;
}

export function PlaylistCard({ playlist, onSubmit, isRelated, priority = false, hideSubmit = false }: PlaylistCardProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [imageError, setImageError] = useState(false);
  const lastSubmission = getLastSubmissionData();
  const hasSubmitted = lastSubmission?.lastTrack && 
    hasSubmittedToPlaylist(playlist.id, lastSubmission.lastTrack.external_urls.spotify);

  const handleClick = () => {
    // Save scroll position if not a related playlist
    if (!isRelated) {
      scrollManager.savePosition(location.pathname, searchParams);
    }

    // Check if we're on a genre page
    const isGenrePage = location.pathname.startsWith('/genre/');
    
    // Scroll to top immediately
    window.scrollTo({ top: 0, behavior: 'instant' });
    
    navigate(`/playlist/${playlist.slug}`, {
      state: { from: isGenrePage ? 'genre' : 'home' }
    });
  };

  return (
    <div className="bg-white shadow-sm hover:shadow-md transition-shadow h-full flex flex-col">
      <div onClick={handleClick} className="cursor-pointer flex-1">
        <div className="relative pb-[100%] bg-gray-100">
          {imageError || !playlist.image_url ? (
            <div className="absolute inset-0 flex items-center justify-center bg-gray-100">
              <ImageIcon className="w-12 h-12 text-gray-400" />
            </div>
          ) : (
            <img 
              src={playlist.image_url}
              alt={playlist.name}
              className="absolute inset-0 w-full h-full object-cover sm:rounded-[2px] lg:rounded-[4px]"
              loading={priority ? "eager" : "lazy"}
              onError={() => setImageError(true)}
            />
          )}
          {playlist.featured && (
            <div className="absolute top-2 right-2 bg-yellow-400 text-yellow-900 px-2 py-1 rounded-full text-xs font-medium flex items-center gap-1">
              <Star className="w-3 h-3" />
              Featured
            </div>
          )}
        </div>
        <div className="p-4 flex flex-col h-[140px]">
          <h3 className="text-base font-bold text-gray-900 mb-1 line-clamp-1 hover:text-green-600 transition-colors">
            {playlist.name}
          </h3>
          <p className="text-sm text-gray-600 mb-2 line-clamp-2 flex-1">
            {playlist.description}
          </p>
          
          <div className="space-y-2">
            <div className="flex items-center gap-4 text-sm">
              <Link 
                to={`/genre/${playlist.genre_slug}`}
                onClick={(e) => e.stopPropagation()}
                className="flex items-center text-gray-700 hover:text-green-600 transition-colors"
              >
                <Music2 className="w-4 h-4 mr-1 flex-shrink-0" />
                <span className="truncate">{playlist.genre}</span>
              </Link>
              <div className="flex items-center text-gray-700">
                <Users className="w-4 h-4 mr-1 flex-shrink-0" />
                <span>{playlist.followers.toLocaleString()}</span>
              </div>
            </div>

            {playlist.curator_name && (
              <div className="flex items-center gap-2 text-sm text-gray-600">
                <User className="w-4 h-4 flex-shrink-0" />
                {playlist.curator_profile_url ? (
                  <a
                    href={playlist.curator_profile_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(e) => e.stopPropagation()}
                    className="truncate hover:text-green-600 transition-colors"
                  >
                    {playlist.curator_name}
                  </a>
                ) : (
                  <span className="truncate">{playlist.curator_name}</span>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      
      {!hideSubmit && (
        <div className="px-4 py-3 border-t border-gray-100 mt-auto">
          {hasSubmitted ? (
            <div className="flex items-center gap-1.5 bg-gray-100 text-gray-500 px-3 py-1.5 rounded-full text-sm">
              <Check className="w-3.5 h-3.5" />
              Submitted
            </div>
          ) : (
            <button
              onClick={(e) => {
                e.stopPropagation();
                onSubmit(playlist.id);
              }}
              className="flex items-center gap-1.5 bg-green-500 text-white px-3 py-1.5 rounded-full text-sm hover:bg-green-600 transition-colors"
            >
              <Send className="w-3.5 h-3.5" />
              Submit
            </button>
          )}
        </div>
      )}
    </div>
  );
}