import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { ArrowLeft, Music2, Loader2 } from 'lucide-react';
import { PlaylistCard } from '../components/PlaylistCard';
import { SubmissionModal } from '../components/SubmissionModal';
import { useGenres } from '../hooks/useGenres';
import { usePlaylists } from '../hooks/usePlaylists';
import { slugify } from '../utils/formatting';
import { Toast } from '../components/Toast';
import { SEO } from '../components/SEO';
import type { TrackSubmission } from '../types';

const genreImage = "https://ftwlzdhkzqfuosdorgjt.supabase.co/storage/v1/object/public/playlistpartner%20public/playlistpartner%20og%20image.png";

interface FilterState {
  search: string;
  sortBy: 'default' | 'followers-high' | 'followers-low';
}

export function SubGenrePage() {
  const { genre, subGenre } = useParams();
  const decodedGenre = genre ? decodeURIComponent(genre) : '';
  const decodedSubGenre = subGenre ? decodeURIComponent(subGenre) : '';
  const { genres, loading: genresLoading } = useGenres();
  const selectedGenre = genres.find(g => g.slug === decodedGenre);
  const selectedSubGenre = selectedGenre?.subGenres.find(
    sg => slugify(sg.name) === decodedSubGenre
  );
  const [selectedPlaylist, setSelectedPlaylist] = useState<any>(null);
  const [showSubmissionModal, setShowSubmissionModal] = useState(false);
  const [toast, setToast] = useState<{ type: 'success' | 'error', message: string } | null>(null);

  const [filters, setFilters] = useState<FilterState>({
    search: '',
    sortBy: 'default'
  });

  const { playlists, loading: playlistsLoading, error, hasMore, loadMore, currentPage, totalCount, itemsPerPage } = usePlaylists({
    ...filters,
    genre: selectedGenre?.name,
    subGenre: selectedSubGenre?.name
  });

  // Show loading state while genres are loading
  if (genresLoading) {
    return (
      <div className="container mx-auto px-4 py-8">
        <div className="flex items-center justify-center min-h-[400px]">
          <Loader2 className="w-8 h-8 text-green-500 animate-spin" />
        </div>
      </div>
    );
  }

  // Only show not found after genres have loaded
  if (!genresLoading && (!selectedGenre || !selectedSubGenre)) {
    return (
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-2xl font-bold mb-4">Sub-Genre Not Found</h1>
        <Link to="/" className="text-green-600 hover:text-green-700">
          Return to Home
        </Link>
      </div>
    );
  }

  const seoTitle = `Free ${selectedSubGenre.name} Spotify Playlist Submission - No Signup`;
  const seoDescription = `Submit your ${selectedSubGenre.name} tracks to ${totalCount} Spotify playlists for free. Connect with independent curators and grow your audience — no signup needed.`;

  const handleSubmit = async (submission: TrackSubmission) => {
    try {
      setShowSubmissionModal(false);
      setToast({
        type: 'success',
        message: 'Track submitted successfully!'
      });
    } catch (error) {
      console.error('Failed to submit track:', error);
      setToast({
        type: 'error',
        message: 'Failed to submit track. Please try again.'
      });
    }
  };

  // Calculate values for pagination display
  const startIndex = (currentPage - 1) * itemsPerPage + 1;
  const endIndex = Math.min(currentPage * itemsPerPage, totalCount);

  return (
    <>
      <SEO 
        title={seoTitle}
        description={seoDescription}
        image={genreImage}
        url={`${window.location.origin}/genre/${selectedGenre.slug}/${slugify(selectedSubGenre.name)}`}
        type="website"
      />

      <main className="container mx-auto px-4 py-8">
        <Link 
          to={`/genre/${encodeURIComponent(selectedGenre.slug)}`} 
          className="inline-flex items-center gap-2 text-green-600 hover:text-green-700 mb-6"
        >
          <ArrowLeft className="w-4 h-4" />
          Back to {selectedGenre.name} Playlists
        </Link>

        <div className="bg-white p-6 rounded-lg shadow-sm mb-8">
          <div className="flex items-start gap-4">
            <div className="p-3 bg-green-50 rounded-lg">
              <Music2 className="w-8 h-8 text-green-600" />
            </div>
            <div>
              <h1 className="text-3xl font-bold mb-2">
                {selectedSubGenre.name} Spotify Playlist Submission
              </h1>
              <p className="text-gray-600 mb-4">
                {selectedSubGenre.description || `Discover curated playlists specializing in ${selectedSubGenre.name} ${selectedGenre.name} music. Submit your tracks to playlist curators who understand and appreciate your specific style.`}
              </p>
              <div className="flex gap-4 text-sm text-gray-600">
                <span>{totalCount} Playlists</span>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-6">
          {playlists.length > 0 && (
            <div className="text-sm text-gray-600 mb-6">
              Showing {startIndex}-{endIndex} of {totalCount} playlists
            </div>
          )}

          {playlistsLoading ? (
            <div className="flex items-center justify-center py-12">
              <Loader2 className="w-8 h-8 text-green-500 animate-spin" />
            </div>
          ) : error ? (
            <div className="text-center py-12">
              <p className="text-red-500">Failed to load playlists. Please try again.</p>
            </div>
          ) : playlists.length === 0 ? (
            <div className="text-center py-12">
              <p className="text-gray-500 text-lg">
                No playlists found in this sub-genre.
              </p>
            </div>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
              {playlists.map(playlist => (
                <PlaylistCard
                  key={playlist.id}
                  playlist={playlist}
                  onSubmit={() => {
                    setSelectedPlaylist(playlist);
                    setShowSubmissionModal(true);
                  }}
                />
              ))}
            </div>
          )}

          {hasMore && !playlistsLoading && (
            <div className="text-center mt-8">
              <button
                onClick={loadMore}
                className="px-6 py-3 bg-green-500 text-white rounded-lg hover:bg-green-600 transition-colors"
              >
                Load More Playlists
              </button>
            </div>
          )}
        </div>

        {showSubmissionModal && selectedPlaylist && (
          <SubmissionModal
            playlist={selectedPlaylist}
            onClose={() => {
              setShowSubmissionModal(false);
              setSelectedPlaylist(null);
            }}
            onSubmit={handleSubmit}
          />
        )}

        {toast && (
          <Toast
            message={toast.message}
            type={toast.type}
            onClose={() => setToast(null)}
          />
        )}
      </main>
    </>
  );
}