import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import { Music2, Users, ArrowLeft, Send, Check, User, AlertCircle } from 'lucide-react';
import { usePlaylist } from '../hooks/usePlaylist';
import { PlaylistCard } from '../components/PlaylistCard';
import { SubmissionModal } from '../components/SubmissionModal';
import { Toast } from '../components/Toast';
import { SEO } from '../components/SEO';
import { supabase } from '../lib/supabase';
import { getLastSubmissionData } from '../utils/storage';
import { hasSubmittedToPlaylist } from '../utils/submissionTracking';
import type { TrackSubmission } from '../types';

export function PlaylistPage() {
  const { slug } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [showSubmissionModal, setShowSubmissionModal] = useState(false);
  const [toast, setToast] = useState<{ message: string; type: 'success' | 'error' | 'info' } | null>(null);
  const [genrePlaylistCount, setGenrePlaylistCount] = useState<number>(0);
  const { playlist, loading, error, relatedPlaylists, isNotFound } = usePlaylist(slug || '');

  // Fetch playlist count for genre when playlist data is available
  useEffect(() => {
    async function fetchGenrePlaylistCount() {
      if (playlist?.genre) {
        const { count } = await supabase
          .from('playlist_details')
          .select('*', { count: 'exact', head: true })
          .eq('genre', playlist.genre);
        
        setGenrePlaylistCount(count || 0);
      }
    }

    if (playlist) {
      fetchGenrePlaylistCount();
    }
  }, [playlist]);

  const lastSubmission = getLastSubmissionData();
  const hasSubmitted = playlist && lastSubmission?.lastTrack && 
    hasSubmittedToPlaylist(playlist.id, lastSubmission.lastTrack.external_urls.spotify);

  const handleBack = () => {
    const from = location.state?.from;
    if (from === 'genre') {
      navigate(-1); // Go back to genre page
    } else {
      navigate('/'); // Go back to home
    }
  };

  const handleSubmit = async (submission: TrackSubmission) => {
    try {
      // Handle submission success
      setShowSubmissionModal(false);
      setToast({
        type: 'success',
        message: `Track submitted to ${playlist?.name}! Watch your inbox for feedback from the curator.`
      });
    } catch (error) {
      console.error('Failed to submit track:', error);
      setToast({
        type: 'error',
        message: 'Failed to submit track. Please try again.'
      });
    }
  };

  if (loading) {
    return (
      <div className="container mx-auto px-4 py-8">
        <div className="animate-pulse">
          <div className="h-8 w-48 bg-gray-200 rounded mb-6"></div>
          <div className="bg-white shadow-lg">
            <div className="grid md:grid-cols-2">
              <div className="aspect-square bg-gray-200"></div>
              <div className="p-6">
                <div className="h-8 w-3/4 bg-gray-200 rounded mb-4"></div>
                <div className="h-4 w-1/2 bg-gray-200 rounded mb-4"></div>
                <div className="h-20 bg-gray-200 rounded mb-4"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (isNotFound || !playlist) {
    return (
      <div className="container mx-auto px-4 py-8">
        <div className="text-center">
          <div className="flex items-center justify-center gap-2 text-red-600 mb-4">
            <AlertCircle className="w-6 h-6" />
            <h2 className="text-2xl font-bold">Playlist Not Found</h2>
          </div>
          <p className="text-gray-600 mb-6">
            The playlist you're looking for doesn't exist or may have been removed.
          </p>
          <button
            onClick={handleBack}
            className="text-green-600 hover:text-green-700 flex items-center gap-2 mx-auto"
          >
            <ArrowLeft className="w-4 h-4" />
            Back to Playlists
          </button>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mx-auto px-4 py-8">
        <div className="text-center">
          <div className="flex items-center justify-center gap-2 text-red-600 mb-4">
            <AlertCircle className="w-6 h-6" />
            <h2 className="text-2xl font-bold">Error Loading Playlist</h2>
          </div>
          <p className="text-gray-600 mb-6">
            {error.message || 'Something went wrong. Please try again.'}
          </p>
          <button
            onClick={handleBack}
            className="text-green-600 hover:text-green-700 flex items-center gap-2 mx-auto"
          >
            <ArrowLeft className="w-4 h-4" />
            Back to Playlists
          </button>
        </div>
      </div>
    );
  }

  const seoDescription = `Get your music featured on ${playlist.name}, a Spotify playlist curated by ${playlist.curator_name || 'Independent Curator'}. No account needed! Access ${genrePlaylistCount} ${playlist.genre} playlists and connect with curators on PlaylistPartner.com.`;

  return (
    <>
      <SEO 
        title={`Submit Music to ${playlist.name} - Free Spotify Playlist Submission`}
        description={seoDescription}
        image={playlist.image_url}
        url={`${window.location.origin}/playlist/${playlist.slug}`}
        type="website"
      />

      <main className="container mx-auto px-4 py-8">
        <button
          onClick={handleBack}
          className="inline-flex items-center gap-2 text-green-600 hover:text-green-700 mb-6"
        >
          <ArrowLeft className="w-4 h-4" />
          Back to Playlists
        </button>

        <div className="bg-white shadow-lg">
          <div className="grid md:grid-cols-2">
            <img 
              src={playlist.image_url} 
              alt={playlist.name}
              className="w-full aspect-square object-cover"
            />
            <div className="p-6 flex flex-col justify-between">
              <div>
                <h1 className="text-3xl md:text-4xl font-bold mb-2">{playlist.name}</h1>
                <div className="flex items-center gap-2 text-gray-600 mb-4">
                  <User className="w-5 h-5" />
                  <span className="text-lg">Curated by </span>
                  {playlist.curator_profile_url ? (
                    <a
                      href={playlist.curator_profile_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-lg hover:text-green-600 transition-colors"
                    >
                      {playlist.curator_name}
                    </a>
                  ) : (
                    <span className="text-lg">{playlist.curator_name || 'Independent Curator'}</span>
                  )}
                </div>
                <p className="text-gray-600 text-lg mb-4">{playlist.description}</p>

                <div className="flex flex-wrap gap-6 mb-4">
                  <Link 
                    to={`/genre/${playlist.genre_slug}`}
                    className="flex items-center text-gray-700 hover:text-green-600 transition-colors"
                  >
                    <Music2 className="w-5 h-5 mr-2" />
                    <span className="text-lg">{playlist.genre}</span>
                  </Link>
                  <div className="flex items-center text-gray-700">
                    <Users className="w-5 h-5 mr-2" />
                    <span className="text-lg">{playlist.followers.toLocaleString()} followers</span>
                  </div>
                </div>

                {playlist.sub_genres?.length > 0 && (
                  <div className="flex flex-wrap gap-2 mb-6">
                    {playlist.sub_genres.map(subGenre => (
                      <Link 
                        key={subGenre}
                        to={`/genre/${playlist.genre_slug}/${subGenre.toLowerCase().replace(/\s+/g, '-')}`}
                        className="px-3 py-1 bg-gray-100 text-gray-700 rounded-full text-sm hover:bg-gray-200 transition-colors"
                      >
                        {subGenre}
                      </Link>
                    ))}
                  </div>
                )}
              </div>

              {hasSubmitted ? (
                <div className="w-full sm:w-auto flex items-center justify-center gap-2 bg-gray-100 text-gray-500 px-6 py-3 font-medium">
                  <Check className="w-5 h-5" />
                  Track Submitted
                </div>
              ) : (
                <button
                  onClick={() => setShowSubmissionModal(true)}
                  className="w-full sm:w-auto flex items-center justify-center gap-2 bg-green-500 text-white px-6 py-3 hover:bg-green-600 transition-colors text-lg font-medium"
                >
                  <Send className="w-5 h-5" />
                  Submit Your Track
                </button>
              )}
            </div>
          </div>
        </div>

        {relatedPlaylists.length > 0 && (
          <div className="mt-12">
            <h2 className="text-2xl font-bold mb-6">Related Playlists</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
              {relatedPlaylists.map(relatedPlaylist => (
                <PlaylistCard
                  key={relatedPlaylist.id}
                  playlist={relatedPlaylist}
                  onSubmit={() => setShowSubmissionModal(true)}
                  isRelated={true}
                  hideSubmit={true}
                />
              ))}
            </div>
          </div>
        )}

        {showSubmissionModal && playlist && (
          <SubmissionModal
            playlist={playlist}
            onClose={() => setShowSubmissionModal(false)}
            onSubmit={handleSubmit}
          />
        )}

        {toast && (
          <Toast
            message={toast.message}
            type={toast.type}
            onClose={() => setToast(null)}
          />
        )}
      </main>
    </>
  );
}