import { useState, useEffect } from 'react';
import { supabase } from '../lib/supabase';
import type { Database } from '../services/database.types';

type PlaylistDetails = Database['public']['Tables']['playlists']['Row'] & {
  genres?: {
    id: string;
    name: string;
    slug: string;
  };
  sub_genres?: string[];
};

export function usePlaylistsAdmin() {
  const [playlists, setPlaylists] = useState<PlaylistDetails[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const fetchPlaylists = async () => {
    try {
      setLoading(true);
      setError(null);

      // First, get all playlists with their genres
      const { data: playlistData, error: playlistError } = await supabase
        .from('playlists')
        .select(`
          *,
          genres (
            id,
            name,
            slug
          )
        `)
        .order('created_at', { ascending: false });

      if (playlistError) throw playlistError;

      // Then, get all sub-genres
      const { data: subGenres, error: subGenresError } = await supabase
        .from('sub_genres')
        .select('*');

      if (subGenresError) throw subGenresError;

      // Map sub-genre IDs to names
      const playlists = playlistData.map(playlist => ({
        ...playlist,
        sub_genres: playlist.sub_genre_ids
          ? playlist.sub_genre_ids
              .map(id => subGenres.find(sg => sg.id === id)?.name)
              .filter(Boolean)
          : []
      }));

      setPlaylists(playlists);
    } catch (err) {
      console.error('Failed to fetch playlists:', err);
      setError(err instanceof Error ? err : new Error('Failed to fetch playlists'));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPlaylists();
  }, []);

  const createPlaylist = async (data: {
    spotify_id: string;
    name: string;
    description: string;
    image_url: string;
    genre_id: string;
    sub_genre_ids?: string[];
    followers: number;
    curator_name?: string | null;
    curator_profile_url?: string | null;
  }) => {
    try {
      const { data: newPlaylist, error } = await supabase
        .from('playlists')
        .insert([{
          spotify_id: data.spotify_id,
          name: data.name,
          description: data.description,
          image_url: data.image_url,
          genre_id: data.genre_id,
          sub_genre_ids: data.sub_genre_ids || [],
          followers: data.followers,
          curator_name: data.curator_name || null,
          curator_profile_url: data.curator_profile_url || null,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString()
        }])
        .select(`
          *,
          genres (
            id,
            name,
            slug
          )
        `)
        .single();

      if (error) throw error;

      // Fetch sub-genre names
      const { data: subGenres } = await supabase
        .from('sub_genres')
        .select('*')
        .in('id', data.sub_genre_ids || []);

      const playlistWithSubGenres = {
        ...newPlaylist,
        sub_genres: subGenres?.map(sg => sg.name) || []
      };

      setPlaylists(prev => [playlistWithSubGenres, ...prev]);
      return playlistWithSubGenres;
    } catch (err) {
      console.error('Failed to create playlist:', err);
      throw err;
    }
  };

  const updatePlaylist = async (id: string, data: Partial<PlaylistDetails>) => {
    try {
      const { data: updatedPlaylist, error } = await supabase
        .from('playlists')
        .update({
          ...data,
          updated_at: new Date().toISOString()
        })
        .eq('id', id)
        .select(`
          *,
          genres (
            id,
            name,
            slug
          )
        `)
        .single();

      if (error) throw error;

      // Fetch sub-genre names if sub_genre_ids were updated
      let subGenreNames: string[] = [];
      if (data.sub_genre_ids) {
        const { data: subGenres } = await supabase
          .from('sub_genres')
          .select('*')
          .in('id', data.sub_genre_ids);
        
        subGenreNames = subGenres?.map(sg => sg.name) || [];
      }

      const playlistWithSubGenres = {
        ...updatedPlaylist,
        sub_genres: subGenreNames
      };

      setPlaylists(prev =>
        prev.map(playlist =>
          playlist.id === id ? playlistWithSubGenres : playlist
        )
      );

      return playlistWithSubGenres;
    } catch (err) {
      console.error('Failed to update playlist:', err);
      throw err;
    }
  };

  const deletePlaylist = async (id: string) => {
    try {
      const { error } = await supabase
        .from('playlists')
        .delete()
        .eq('id', id);

      if (error) throw error;

      setPlaylists(prev => prev.filter(playlist => playlist.id !== id));
    } catch (err) {
      console.error('Failed to delete playlist:', err);
      throw err;
    }
  };

  return {
    playlists,
    loading,
    error,
    refresh: fetchPlaylists,
    createPlaylist,
    updatePlaylist,
    deletePlaylist
  };
}