import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { AlertCircle, CheckCircle2, X } from 'lucide-react';

interface ToastProps {
  message: string;
  type: 'success' | 'error' | 'info';
  onClose: () => void;
}

function ToastContent({ message, type, onClose }: ToastProps) {
  const [isExiting, setIsExiting] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsExiting(true);
    }, 4700); // Start exit animation before 5s mark

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (isExiting) {
      const exitTimer = setTimeout(() => {
        onClose();
      }, 300); // Duration of exit animation
      return () => clearTimeout(exitTimer);
    }
  }, [isExiting, onClose]);

  const bgColor = {
    success: 'bg-green-500',
    error: 'bg-red-500',
    info: 'bg-blue-500'
  }[type];

  const Icon = {
    success: CheckCircle2,
    error: AlertCircle,
    info: AlertCircle
  }[type];

  return (
    <div 
      className={`
        fixed bottom-4 right-4 
        ${bgColor} text-white px-4 py-3 rounded-lg shadow-lg 
        flex items-center gap-3 max-w-md
        transition-all duration-300
        ${isExiting ? 'opacity-0 translate-y-2' : 'opacity-100 translate-y-0'}
      `}
    >
      <Icon className="w-5 h-5 flex-shrink-0" />
      <p className="flex-1">{message}</p>
      <button
        onClick={() => setIsExiting(true)}
        className="p-1 hover:bg-white/20 rounded-full transition-colors"
      >
        <X className="w-4 h-4" />
      </button>
    </div>
  );
}

export function Toast(props: ToastProps) {
  const [container] = useState(() => {
    // Create container if it doesn't exist
    let element = document.getElementById('toast-container');
    if (!element) {
      element = document.createElement('div');
      element.id = 'toast-container';
      document.body.appendChild(element);
    }
    return element;
  });

  return createPortal(
    <ToastContent {...props} />,
    container
  );
}