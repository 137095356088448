import { GENRES } from '../data/playlists';
import { slugify, titleCase } from './formatting';

export async function setupGenresAndSubGenres() {
  // Mock implementation
  return { success: true };
}

export async function getGenreWithSubGenres(genreSlug: string) {
  const genre = GENRES.find(g => g.slug === genreSlug);
  return genre || null;
}

export async function getAllGenresWithSubGenres() {
  return GENRES;
}