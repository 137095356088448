import React, { useState } from 'react';
import { Save, RefreshCw, Loader2, AlertCircle, Sparkles } from 'lucide-react';
import { Toast } from '../components/Toast';
import { setupGenresAndSubGenres } from '../utils/genre-management';
import { supabase } from '../lib/supabase';
import { cleanDescription } from '../utils/description-cleaner';

export function AdminSettings() {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [genreLoading, setGenreLoading] = useState(false);
  const [cleaningDescriptions, setCleaningDescriptions] = useState(false);
  const [toast, setToast] = useState<{ type: 'success' | 'error', message: string } | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (newPassword !== confirmPassword) {
      setToast({
        type: 'error',
        message: 'New passwords do not match'
      });
      return;
    }

    setLoading(true);
    try {
      // Password update logic here...
      setToast({
        type: 'success',
        message: 'Password updated successfully'
      });

      // Clear form
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
    } catch (error) {
      console.error('Failed to update password:', error);
      setToast({
        type: 'error',
        message: 'Failed to update password'
      });
    } finally {
      setLoading(false);
    }
  };

  const handleGenreSetup = async () => {
    setGenreLoading(true);
    try {
      await setupGenresAndSubGenres();
      setToast({
        type: 'success',
        message: 'Genres and sub-genres have been set up successfully'
      });
    } catch (error) {
      console.error('Failed to setup genres:', error);
      setToast({
        type: 'error',
        message: 'Failed to setup genres and sub-genres'
      });
    } finally {
      setGenreLoading(false);
    }
  };

  const handleCleanDescriptions = async () => {
    if (!window.confirm('This will clean all playlist descriptions. Continue?')) {
      return;
    }

    setCleaningDescriptions(true);
    try {
      // Fetch all playlists
      const { data: playlists, error: fetchError } = await supabase
        .from('playlists')
        .select('id, description');

      if (fetchError) throw fetchError;
      if (!playlists) throw new Error('No playlists found');

      let cleaned = 0;
      let updated = 0;

      // Process playlists in batches
      const BATCH_SIZE = 50;
      for (let i = 0; i < playlists.length; i += BATCH_SIZE) {
        const batch = playlists.slice(i, Math.min(i + BATCH_SIZE, playlists.length));
        
        for (const playlist of batch) {
          cleaned++;
          const { description: cleanedDescription, extractedEmail } = cleanDescription(playlist.description);
          
          if (cleanedDescription !== playlist.description || extractedEmail) {
            const { error: updateError } = await supabase
              .from('playlists')
              .update({
                description: cleanedDescription,
                curator_email: extractedEmail,
                updated_at: new Date().toISOString()
              })
              .eq('id', playlist.id);

            if (updateError) {
              console.error(`Failed to update playlist ${playlist.id}:`, updateError);
              continue;
            }

            updated++;
          }
        }
      }

      setToast({
        type: 'success',
        message: `Processed ${cleaned} playlists, updated ${updated} descriptions`
      });
    } catch (error) {
      console.error('Failed to clean descriptions:', error);
      setToast({
        type: 'error',
        message: error instanceof Error ? error.message : 'Failed to clean descriptions'
      });
    } finally {
      setCleaningDescriptions(false);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold mb-6">Settings</h1>

      <div className="space-y-6 max-w-md">
        <div className="bg-white p-6 rounded-lg shadow-sm">
          <h2 className="text-lg font-medium mb-4">Genre Management</h2>
          <button
            onClick={handleGenreSetup}
            disabled={genreLoading}
            className="flex items-center gap-2 bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 disabled:bg-gray-400 disabled:cursor-not-allowed"
          >
            {genreLoading ? (
              <Loader2 className="w-5 h-5 animate-spin" />
            ) : (
              <RefreshCw className="w-5 h-5" />
            )}
            {genreLoading ? 'Setting up...' : 'Setup Genres & Sub-genres'}
          </button>
          <p className="mt-2 text-sm text-gray-600">
            This will create or update all genres and their associated sub-genres.
          </p>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-sm">
          <h2 className="text-lg font-medium mb-4">Clean Playlist Descriptions</h2>
          <button
            onClick={handleCleanDescriptions}
            disabled={cleaningDescriptions}
            className="flex items-center gap-2 bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 disabled:bg-gray-400 disabled:cursor-not-allowed"
          >
            {cleaningDescriptions ? (
              <Loader2 className="w-5 h-5 animate-spin" />
            ) : (
              <Sparkles className="w-5 h-5" />
            )}
            {cleaningDescriptions ? 'Cleaning...' : 'Clean All Descriptions'}
          </button>
          <p className="mt-2 text-sm text-gray-600">
            This will clean all playlist descriptions by removing HTML, URLs, and extracting curator emails.
          </p>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-sm">
          <h2 className="text-lg font-medium mb-4">Change Password</h2>
          
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label htmlFor="currentPassword" className="block text-sm font-medium text-gray-700 mb-1">
                Current Password
              </label>
              <input
                type="password"
                id="currentPassword"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent"
              />
            </div>

            <div>
              <label htmlFor="newPassword" className="block text-sm font-medium text-gray-700 mb-1">
                New Password
              </label>
              <input
                type="password"
                id="newPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent"
              />
            </div>

            <div>
              <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700 mb-1">
                Confirm New Password
              </label>
              <input
                type="password"
                id="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent"
              />
            </div>

            <button
              type="submit"
              disabled={loading}
              className="w-full flex items-center justify-center gap-2 bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 disabled:bg-gray-400"
            >
              {loading ? (
                <Loader2 className="w-5 h-5 animate-spin" />
              ) : (
                <Save className="w-5 h-5" />
              )}
              {loading ? 'Updating...' : 'Update Password'}
            </button>
          </form>
        </div>
      </div>

      {toast && (
        <Toast
          message={toast.message}
          type={toast.type}
          onClose={() => setToast(null)}
        />
      )}
    </div>
  );
}