import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowRight, ArrowLeft } from 'lucide-react';
import { TrackSearch } from '../components/TrackSearch';
import type { SpotifyTrack } from '../utils/spotify';
import { saveSubmissionData } from '../utils/storage';

export function SubmissionFlow() {
  const navigate = useNavigate();
  const [selectedTrack, setSelectedTrack] = useState<SpotifyTrack | null>(null);

  const handleTrackSelect = (track: SpotifyTrack) => {
    // Save the track data for the playlist page
    saveSubmissionData({
      artistName: track.artists[0]?.name || '',
      email: '',
      track
    });

    // Navigate to genre selection
    navigate('/submit/genre', { 
      state: { selectedTrack: track }
    });
  };

  return (
    <div className="container mx-auto px-4 py-8 max-w-2xl">
      <button
        onClick={() => navigate('/')}
        className="flex items-center gap-2 text-gray-600 hover:text-gray-800 transition-colors mb-6"
      >
        <ArrowLeft className="w-5 h-5" />
        Back to Playlists
      </button>

      <div className="space-y-6">
        <div className="text-center">
          <h1 className="text-3xl font-bold mb-2">Submit Your Track</h1>
          <p className="text-gray-600">Search for your track on Spotify to get started</p>
        </div>
        
        <div className="bg-white p-6 rounded-xl shadow-lg">
          <TrackSearch
            onTrackSelect={handleTrackSelect}
            selectedTrack={selectedTrack}
            onClearTrack={() => setSelectedTrack(null)}
          />
        </div>

        {selectedTrack && (
          <button
            onClick={() => handleTrackSelect(selectedTrack)}
            className="w-full flex items-center justify-center gap-2 bg-green-500 text-white p-4 rounded-lg hover:bg-green-600 transition-colors"
          >
            Continue to Genre Selection
            <ArrowRight className="w-5 h-5" />
          </button>
        )}
      </div>
    </div>
  );
}