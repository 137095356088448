import React from 'react';
import { Helmet } from 'react-helmet-async';

interface SEOProps {
  title?: string;
  description?: string;
  image?: string;
  url?: string;
  type?: string;
  noIndex?: boolean;
}

export function SEO({ 
  title, 
  description,
  image = "https://ftwlzdhkzqfuosdorgjt.supabase.co/storage/v1/object/public/playlistpartner%20public/playlistpartner%20og%20image.png",
  url,
  type = "website",
  noIndex = false
}: SEOProps) {
  const siteTitle = title || "Free Spotify Playlist Submission – No Signup | PlaylistPartner";
  const defaultDescription = "Submit your music to thousands of independent Spotify playlist curators for free. No signup required—connect with curators and grow your audience today with PlaylistPartner.";
  const metaDescription = description || defaultDescription;
  const canonicalUrl = url || window.location.href.split('?')[0];

  return (
    <Helmet>
      {/* Basic tags */}
      <title>{siteTitle}</title>
      <meta name="description" content={metaDescription} data-react-helmet="true" />
      <link rel="canonical" href={canonicalUrl} />
      
      {/* Robots */}
      <meta 
        name="robots" 
        content={noIndex ? "noindex, nofollow" : "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"} 
      />

      {/* OpenGraph */}
      <meta property="og:site_name" content="PlaylistPartner" />
      <meta property="og:title" content={siteTitle} data-react-helmet="true" />
      <meta property="og:description" content={metaDescription} data-react-helmet="true" />
      <meta property="og:type" content={type} />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:image" content={image} data-react-helmet="true" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={siteTitle} data-react-helmet="true" />
      <meta name="twitter:description" content={metaDescription} data-react-helmet="true" />
      <meta name="twitter:image" content={image} data-react-helmet="true" />
      <meta name="twitter:site" content="@playlistpartner" />

      {/* Schema.org */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "WebSite",
          "name": "PlaylistPartner",
          "url": canonicalUrl,
          "description": metaDescription,
          "potentialAction": {
            "@type": "SearchAction",
            "target": `${window.location.origin}/search?q={search_term_string}`,
            "query-input": "required name=search_term_string"
          }
        })}
      </script>
    </Helmet>
  );
}