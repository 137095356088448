import React from 'react';
import { Outlet, Link, useLocation, useNavigate } from 'react-router-dom';
import { LayoutDashboard, Music2, InboxIcon, Settings, LogOut, List, Upload } from 'lucide-react';
import { ProtectedRoute } from '../components/ProtectedRoute';
import { useAuth } from '../contexts/AuthContext';

export function AdminLayout() {
  const location = useLocation();
  const navigate = useNavigate();
  const { signOut } = useAuth();
  
  const navItems = [
    { path: '/admin', icon: LayoutDashboard, label: 'Dashboard' },
    { path: '/admin/playlists', icon: Music2, label: 'Playlists' },
    { path: '/admin/submissions', icon: InboxIcon, label: 'Submissions' },
    { path: '/admin/genres', icon: List, label: 'Genres' },
    { path: '/admin/import', icon: Upload, label: 'Import' },
    { path: '/admin/settings', icon: Settings, label: 'Settings' },
  ];

  const handleLogout = async () => {
    try {
      await signOut();
      navigate('/admin/login');
    } catch (error) {
      console.error('Failed to log out:', error);
    }
  };

  return (
    <ProtectedRoute>
      <div className="min-h-screen bg-gray-100">
        <div className="flex h-screen">
          <div className="w-64 bg-white shadow-lg">
            <div className="h-full flex flex-col">
              <div className="flex items-center justify-center h-16 px-4 border-b">
                <h1 className="text-xl font-bold text-gray-900">Admin Panel</h1>
              </div>
              
              <nav className="flex-1 px-2 py-4 space-y-1">
                {navItems.map(({ path, icon: Icon, label }) => (
                  <Link
                    key={path}
                    to={path}
                    className={`flex items-center px-4 py-2 text-sm rounded-lg ${
                      location.pathname === path
                        ? 'bg-green-50 text-green-700'
                        : 'text-gray-600 hover:bg-gray-50'
                    }`}
                  >
                    <Icon className="w-5 h-5 mr-3" />
                    {label}
                  </Link>
                ))}
              </nav>

              <div className="p-4 border-t">
                <button
                  onClick={handleLogout}
                  className="flex items-center w-full px-4 py-2 text-sm text-red-600 hover:bg-red-50 rounded-lg transition-colors"
                >
                  <LogOut className="w-5 h-5 mr-3" />
                  Logout
                </button>
              </div>
            </div>
          </div>

          <div className="flex-1 overflow-auto">
            <div className="p-8">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </ProtectedRoute>
  );
}