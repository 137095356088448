import React, { useState } from 'react';
import { Search, CheckCircle2, XCircle, Loader2, AlertCircle, MessageSquare, History } from 'lucide-react';
import { Toast } from '../components/Toast';
import { useSubmissions } from '../hooks/useSubmissions';
import { SubmissionDetails } from '../components/SubmissionDetails';

interface FilterState {
  search: string;
  status: '' | 'pending' | 'approved' | 'rejected';
  startDate: string;
  endDate: string;
}

export function AdminSubmissions() {
  const [toast, setToast] = useState<{ type: 'success' | 'error', message: string } | null>(null);
  const [selectedSubmission, setSelectedSubmission] = useState<any>(null);
  const [filters, setFilters] = useState<FilterState>({
    search: '',
    status: '',
    startDate: '',
    endDate: ''
  });

  const { submissions, loading, error, updateStatus } = useSubmissions();

  const handleStatusChange = async (id: string, status: 'approved' | 'rejected' | 'pending') => {
    try {
      await updateStatus(id, status);
      setToast({
        type: 'success',
        message: `Submission marked as ${status}`
      });
    } catch (err) {
      console.error('Failed to update status:', err);
      setToast({
        type: 'error',
        message: 'Failed to update submission status'
      });
    }
  };

  const filteredSubmissions = submissions.filter(submission => {
    if (filters.search && !submission.track_name.toLowerCase().includes(filters.search.toLowerCase()) &&
        !submission.artist_name.toLowerCase().includes(filters.search.toLowerCase()) &&
        !submission.email.toLowerCase().includes(filters.search.toLowerCase())) {
      return false;
    }
    if (filters.status && submission.status !== filters.status) {
      return false;
    }
    if (filters.startDate && new Date(submission.created_at) < new Date(filters.startDate)) {
      return false;
    }
    if (filters.endDate && new Date(submission.created_at) > new Date(filters.endDate)) {
      return false;
    }
    return true;
  });

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold mb-6">Submissions</h1>

      {/* Filters */}
      <div className="bg-white p-4 rounded-lg shadow-sm mb-6">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
            <input
              type="text"
              placeholder="Search submissions..."
              value={filters.search}
              onChange={(e) => setFilters({ ...filters, search: e.target.value })}
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg"
            />
          </div>

          <select
            value={filters.status}
            onChange={(e) => setFilters({ ...filters, status: e.target.value as FilterState['status'] })}
            className="border border-gray-300 rounded-lg px-4 py-2"
          >
            <option value="">All Status</option>
            <option value="pending">Pending</option>
            <option value="approved">Approved</option>
            <option value="rejected">Rejected</option>
          </select>

          <input
            type="date"
            value={filters.startDate}
            onChange={(e) => setFilters({ ...filters, startDate: e.target.value })}
            className="border border-gray-300 rounded-lg px-4 py-2"
            placeholder="Start Date"
          />

          <input
            type="date"
            value={filters.endDate}
            onChange={(e) => setFilters({ ...filters, endDate: e.target.value })}
            className="border border-gray-300 rounded-lg px-4 py-2"
            placeholder="End Date"
          />
        </div>
      </div>

      {/* Submissions Table */}
      <div className="bg-white rounded-lg shadow-sm overflow-hidden">
        <table className="w-full">
          <thead>
            <tr className="bg-gray-50">
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">Track</th>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">Artist</th>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">Email</th>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">Playlist</th>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">Status</th>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">Date</th>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">Actions</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {loading ? (
              <tr>
                <td colSpan={7} className="px-6 py-8 text-center">
                  <Loader2 className="w-6 h-6 text-green-500 animate-spin mx-auto" />
                </td>
              </tr>
            ) : error ? (
              <tr>
                <td colSpan={7} className="px-6 py-8 text-center">
                  <div className="flex items-center justify-center gap-2 text-red-600">
                    <AlertCircle className="w-5 h-5" />
                    <span>Failed to load submissions</span>
                  </div>
                </td>
              </tr>
            ) : filteredSubmissions.length === 0 ? (
              <tr>
                <td colSpan={7} className="px-6 py-8 text-center text-gray-500">
                  No submissions found
                </td>
              </tr>
            ) : (
              filteredSubmissions.map((submission) => (
                <tr 
                  key={submission.id} 
                  className="hover:bg-gray-50 cursor-pointer"
                  onClick={() => setSelectedSubmission(submission)}
                >
                  <td className="px-6 py-4">
                    <a 
                      href={submission.track_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-green-600 hover:text-green-700"
                      onClick={e => e.stopPropagation()}
                    >
                      {submission.track_name}
                    </a>
                  </td>
                  <td className="px-6 py-4">{submission.artist_name}</td>
                  <td className="px-6 py-4">
                    <a 
                      href={`mailto:${submission.email}`}
                      className="text-green-600 hover:text-green-700"
                      onClick={e => e.stopPropagation()}
                    >
                      {submission.email}
                    </a>
                  </td>
                  <td className="px-6 py-4">{submission.playlist?.name}</td>
                  <td className="px-6 py-4">
                    <span className={`inline-flex items-center gap-1 px-2.5 py-0.5 rounded-full text-xs font-medium ${
                      submission.status === 'approved' ? 'bg-green-100 text-green-800' :
                      submission.status === 'rejected' ? 'bg-red-100 text-red-800' :
                      'bg-yellow-100 text-yellow-800'
                    }`}>
                      {submission.status === 'approved' && <CheckCircle2 className="w-3 h-3" />}
                      {submission.status === 'rejected' && <XCircle className="w-3 h-3" />}
                      {submission.status.charAt(0).toUpperCase() + submission.status.slice(1)}
                    </span>
                  </td>
                  <td className="px-6 py-4">
                    {new Date(submission.created_at).toLocaleDateString()}
                  </td>
                  <td className="px-6 py-4" onClick={e => e.stopPropagation()}>
                    <div className="flex gap-2">
                      {/* Show Approve button only for pending submissions */}
                      {submission.status === 'pending' && (
                        <button
                          onClick={() => handleStatusChange(submission.id, 'approved')}
                          className="p-1 text-green-600 hover:bg-green-50 rounded"
                          title="Approve submission"
                        >
                          <CheckCircle2 className="w-5 h-5" />
                        </button>
                      )}

                      {/* Show Return to Pending for both approved and rejected submissions */}
                      {(submission.status === 'approved' || submission.status === 'rejected') && (
                        <button
                          onClick={() => handleStatusChange(submission.id, 'pending')}
                          className="p-1 text-yellow-600 hover:bg-yellow-50 rounded"
                          title="Return to pending"
                        >
                          <History className="w-5 h-5" />
                        </button>
                      )}

                      {/* Show Reject button only for pending submissions */}
                      {submission.status === 'pending' && (
                        <button
                          onClick={() => handleStatusChange(submission.id, 'rejected')}
                          className="p-1 text-red-600 hover:bg-red-50 rounded"
                          title="Reject submission"
                        >
                          <XCircle className="w-5 h-5" />
                        </button>
                      )}

                      <button
                        onClick={() => setSelectedSubmission(submission)}
                        className="p-1 text-blue-600 hover:bg-blue-50 rounded"
                        title="View details"
                      >
                        <MessageSquare className="w-5 h-5" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      {selectedSubmission && (
        <SubmissionDetails 
          submission={selectedSubmission} 
          onClose={() => setSelectedSubmission(null)} 
        />
      )}

      {toast && (
        <Toast
          message={toast.message}
          type={toast.type}
          onClose={() => setToast(null)}
        />
      )}
    </div>
  );
}