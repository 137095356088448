import React, { useState } from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../components/ui/tabs';
import { GenreImport } from '../components/GenreImport';
import { PlaylistImport } from '../components/PlaylistImport';
import { PlaylistSync } from '../components/PlaylistSync';
import { Toast } from '../components/Toast';

export function AdminImport() {
  const [toast, setToast] = useState<{ type: 'success' | 'error', message: string } | null>(null);

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold mb-6">Import & Sync Data</h1>

      <div className="space-y-6">
        <PlaylistSync 
          onComplete={() => setToast({
            type: 'success',
            message: 'Successfully synced playlists with Spotify'
          })}
        />

        <Tabs defaultValue="genres" className="space-y-6">
          <TabsList>
            <TabsTrigger value="genres">Genres & Sub-genres</TabsTrigger>
            <TabsTrigger value="playlists">Playlists</TabsTrigger>
          </TabsList>

          <TabsContent value="genres">
            <GenreImport 
              onSuccess={() => setToast({
                type: 'success',
                message: 'Successfully imported genres and sub-genres'
              })}
            />
          </TabsContent>

          <TabsContent value="playlists">
            <PlaylistImport
              onSuccess={() => setToast({
                type: 'success',
                message: 'Successfully imported playlists'
              })}
            />
          </TabsContent>
        </Tabs>
      </div>

      {toast && (
        <Toast
          message={toast.message}
          type={toast.type}
          onClose={() => setToast(null)}
        />
      )}
    </div>
  );
}