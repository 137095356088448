import React from 'react';
import { Users, Music2, InboxIcon, TrendingUp } from 'lucide-react';

interface StatCard {
  label: string;
  value: string;
  icon: React.ElementType;
  change: string;
  changeType: 'increase' | 'decrease';
}

interface DashboardStatsProps {
  stats: {
    totalPlaylists: number;
    activeSubmissions: number;
    totalUsers: number;
    submissionRate: number;
  } | null;
}

export function DashboardStats({ stats }: DashboardStatsProps) {
  const statCards: StatCard[] = [
    {
      label: 'Total Playlists',
      value: stats?.totalPlaylists.toLocaleString() || '0',
      icon: Music2,
      change: '+12%',
      changeType: 'increase'
    },
    {
      label: 'Active Submissions',
      value: stats?.activeSubmissions.toLocaleString() || '0',
      icon: InboxIcon,
      change: '+5.4%',
      changeType: 'increase'
    },
    {
      label: 'Total Users',
      value: stats?.totalUsers.toLocaleString() || '0',
      icon: Users,
      change: '+2.3%',
      changeType: 'increase'
    },
    {
      label: 'Submission Rate',
      value: `${stats?.submissionRate || 0}%`,
      icon: TrendingUp,
      change: '+8.1%',
      changeType: 'increase'
    }
  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
      {statCards.map((stat) => {
        const Icon = stat.icon;
        return (
          <div
            key={stat.label}
            className="bg-white p-6 rounded-lg shadow-sm"
          >
            <div className="flex items-center">
              <div className="p-2 bg-green-50 rounded-lg">
                <Icon className="h-6 w-6 text-green-600" />
              </div>
              <div className="ml-4">
                <p className="text-sm font-medium text-gray-600">{stat.label}</p>
                <p className="text-2xl font-semibold text-gray-900">{stat.value}</p>
              </div>
            </div>
            <div className="mt-4">
              <span className={`text-sm ${
                stat.changeType === 'increase' ? 'text-green-600' : 'text-red-600'
              }`}>
                {stat.change}
              </span>
              <span className="text-sm text-gray-600"> from last month</span>
            </div>
          </div>
        );
      })}
    </div>
  );
}