import React from 'react';
import { X, ExternalLink, Mail, Clock, Music2, ListMusic } from 'lucide-react';
import type { Database } from '../services/database.types';

type Submission = Database['public']['Tables']['submissions']['Row'] & {
  playlist?: {
    name: string;
  };
};

interface SubmissionDetailsProps {
  submission: Submission;
  onClose: () => void;
}

export function SubmissionDetails({ submission, onClose }: SubmissionDetailsProps) {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg max-w-2xl w-full p-6">
        <div className="flex justify-between items-center mb-6">
          <h3 className="text-xl font-semibold">Submission Details</h3>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X className="w-5 h-5" />
          </button>
        </div>

        <div className="space-y-6">
          {/* Track Information */}
          <div>
            <h4 className="text-sm font-medium text-gray-500 mb-2">Track Information</h4>
            <div className="bg-gray-50 p-4 rounded-lg space-y-3">
              <div className="flex items-center gap-2">
                <Music2 className="w-4 h-4 text-gray-400" />
                <span className="font-medium">{submission.track_name}</span>
              </div>
              <div className="flex items-center gap-2">
                <a 
                  href={submission.track_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-green-600 hover:text-green-700 flex items-center gap-1"
                >
                  <ExternalLink className="w-4 h-4" />
                  Open in Spotify
                </a>
              </div>
            </div>
          </div>

          {/* Artist & Contact */}
          <div>
            <h4 className="text-sm font-medium text-gray-500 mb-2">Artist & Contact</h4>
            <div className="bg-gray-50 p-4 rounded-lg space-y-3">
              <p><span className="font-medium">Artist Name:</span> {submission.artist_name}</p>
              <div className="flex items-center gap-2">
                <Mail className="w-4 h-4 text-gray-400" />
                <a 
                  href={`mailto:${submission.email}`}
                  className="text-green-600 hover:text-green-700"
                >
                  {submission.email}
                </a>
              </div>
            </div>
          </div>

          {/* Playlist */}
          <div>
            <h4 className="text-sm font-medium text-gray-500 mb-2">Playlist</h4>
            <div className="bg-gray-50 p-4 rounded-lg">
              <div className="flex items-center gap-2">
                <ListMusic className="w-4 h-4 text-gray-400" />
                <span className="font-medium">{submission.playlist?.name}</span>
              </div>
            </div>
          </div>

          {/* Message */}
          {submission.message && (
            <div>
              <h4 className="text-sm font-medium text-gray-500 mb-2">Message</h4>
              <div className="bg-gray-50 p-4 rounded-lg">
                <p className="text-gray-700 whitespace-pre-wrap">{submission.message}</p>
              </div>
            </div>
          )}

          {/* Timestamps */}
          <div>
            <h4 className="text-sm font-medium text-gray-500 mb-2">Timestamps</h4>
            <div className="bg-gray-50 p-4 rounded-lg space-y-2">
              <div className="flex items-center gap-2">
                <Clock className="w-4 h-4 text-gray-400" />
                <span>Submitted: {new Date(submission.created_at).toLocaleString()}</span>
              </div>
              {submission.reviewed_at && (
                <div className="flex items-center gap-2">
                  <Clock className="w-4 h-4 text-gray-400" />
                  <span>Reviewed: {new Date(submission.reviewed_at).toLocaleString()}</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}