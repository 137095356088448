import React, { useState } from 'react';
import { Plus, Search, Upload, Edit, Trash2, Loader2, AlertCircle, RefreshCw } from 'lucide-react';
import { Toast } from '../components/Toast';
import { PlaylistForm } from '../components/PlaylistForm';
import { PlaylistSyncModal } from '../components/PlaylistSyncModal';
import { usePlaylistsAdmin } from '../hooks/usePlaylistsAdmin';
import { formatNumber } from '../utils/formatting';

export function AdminPlaylists() {
  const [showPlaylistForm, setShowPlaylistForm] = useState(false);
  const [showSyncModal, setShowSyncModal] = useState(false);
  const [editingPlaylist, setEditingPlaylist] = useState<any>(null);
  const [toast, setToast] = useState<{ type: 'success' | 'error', message: string } | null>(null);
  const [filters, setFilters] = useState({
    search: '',
    genre: '',
    status: ''
  });

  const { playlists, loading, error, refresh, createPlaylist, updatePlaylist, deletePlaylist } = usePlaylistsAdmin();

  const handleSyncSuccess = () => {
    setShowSyncModal(false);
    setToast({
      type: 'success',
      message: 'Playlists synced successfully'
    });
    refresh();
  };

  const handleSavePlaylist = async (data: any) => {
    try {
      if (editingPlaylist) {
        await updatePlaylist(editingPlaylist.id, data);
        setToast({
          type: 'success',
          message: 'Playlist updated successfully'
        });
      } else {
        await createPlaylist(data);
        setToast({
          type: 'success',
          message: 'Playlist created successfully'
        });
      }
      setShowPlaylistForm(false);
      setEditingPlaylist(null);
      refresh();
    } catch (err) {
      setToast({
        type: 'error',
        message: err instanceof Error ? err.message : 'Failed to save playlist'
      });
    }
  };

  const handleDelete = async (id: string, name: string) => {
    if (!window.confirm(`Are you sure you want to delete "${name}"?`)) {
      return;
    }

    try {
      await deletePlaylist(id);
      setToast({
        type: 'success',
        message: `${name} has been deleted`
      });
      refresh();
    } catch (err) {
      setToast({
        type: 'error',
        message: 'Failed to delete playlist'
      });
    }
  };

  const filteredPlaylists = playlists.filter(playlist => {
    if (filters.search && !playlist.name.toLowerCase().includes(filters.search.toLowerCase())) {
      return false;
    }
    if (filters.genre && playlist.genre_id !== filters.genre) {
      return false;
    }
    if (filters.status === 'claimed' && !playlist.claimed) {
      return false;
    }
    if (filters.status === 'unclaimed' && playlist.claimed) {
      return false;
    }
    return true;
  });

  if (error) {
    return (
      <div className="p-8 text-center">
        <div className="flex items-center justify-center gap-2 text-red-600">
          <AlertCircle className="w-5 h-5" />
          <p>Failed to load playlists</p>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Playlists</h1>
        <div className="flex gap-3">
          <button
            onClick={() => setShowSyncModal(true)}
            className="flex items-center gap-2 bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
          >
            <RefreshCw className="w-5 h-5" />
            Sync
          </button>
          <button 
            onClick={() => setShowPlaylistForm(true)}
            className="flex items-center gap-2 bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600"
          >
            <Plus className="w-5 h-5" />
            Add Playlist
          </button>
        </div>
      </div>

      {/* Filters */}
      <div className="bg-white p-4 rounded-lg shadow-sm mb-6">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
            <input
              type="text"
              placeholder="Search playlists..."
              value={filters.search}
              onChange={(e) => setFilters({ ...filters, search: e.target.value })}
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg"
            />
          </div>

          <select
            value={filters.genre}
            onChange={(e) => setFilters({ ...filters, genre: e.target.value })}
            className="border border-gray-300 rounded-lg px-4 py-2"
          >
            <option value="">All Genres</option>
            {Array.from(new Set(playlists.map(p => p.genre_id))).map(genreId => {
              const genre = playlists.find(p => p.genre_id === genreId)?.genres;
              return genre ? (
                <option key={genre.id} value={genre.id}>{genre.name}</option>
              ) : null;
            })}
          </select>

          <select
            value={filters.status}
            onChange={(e) => setFilters({ ...filters, status: e.target.value })}
            className="border border-gray-300 rounded-lg px-4 py-2"
          >
            <option value="">All Status</option>
            <option value="claimed">Claimed</option>
            <option value="unclaimed">Unclaimed</option>
          </select>
        </div>
      </div>

      {/* Playlists Table */}
      <div className="bg-white rounded-lg shadow-sm overflow-hidden">
        <table className="w-full">
          <thead>
            <tr className="bg-gray-50">
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">Name</th>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">Genre</th>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">Sub-genres</th>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">Curator</th>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">Followers</th>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">Status</th>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">Actions</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {loading ? (
              <tr>
                <td colSpan={7} className="px-6 py-8 text-center">
                  <Loader2 className="w-6 h-6 text-green-500 animate-spin mx-auto" />
                </td>
              </tr>
            ) : filteredPlaylists.length === 0 ? (
              <tr>
                <td colSpan={7} className="px-6 py-8 text-center text-gray-500">
                  No playlists found
                </td>
              </tr>
            ) : (
              filteredPlaylists.map((playlist) => (
                <tr key={playlist.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4">
                    <div className="flex items-center">
                      <img
                        src={playlist.image_url}
                        alt={playlist.name}
                        className="w-10 h-10 rounded-sm object-cover mr-3"
                      />
                      <span className="font-medium">{playlist.name}</span>
                    </div>
                  </td>
                  <td className="px-6 py-4">{playlist.genres?.name}</td>
                  <td className="px-6 py-4">
                    {playlist.sub_genres?.map((subGenre: string) => (
                      <span 
                        key={subGenre}
                        className="inline-block px-2 py-1 bg-gray-100 text-gray-700 text-xs rounded-full mr-1 mb-1"
                      >
                        {subGenre}
                      </span>
                    ))}
                  </td>
                  <td className="px-6 py-4">
                    {playlist.curator_name || 'Unclaimed'}
                  </td>
                  <td className="px-6 py-4">
                    {formatNumber(playlist.followers)}
                  </td>
                  <td className="px-6 py-4">
                    <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                      playlist.claimed 
                        ? 'bg-green-100 text-green-800'
                        : 'bg-yellow-100 text-yellow-800'
                    }`}>
                      {playlist.claimed ? 'Claimed' : 'Unclaimed'}
                    </span>
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex items-center gap-2">
                      <button 
                        onClick={() => setEditingPlaylist(playlist)}
                        className="p-1 hover:bg-gray-100 rounded"
                      >
                        <Edit className="w-4 h-4 text-gray-500" />
                      </button>
                      <button 
                        onClick={() => handleDelete(playlist.id, playlist.name)}
                        className="p-1 hover:bg-gray-100 rounded"
                      >
                        <Trash2 className="w-4 h-4 text-red-500" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      {(showPlaylistForm || editingPlaylist) && (
        <PlaylistForm
          playlist={editingPlaylist}
          onClose={() => {
            setShowPlaylistForm(false);
            setEditingPlaylist(null);
          }}
          onSuccess={handleSavePlaylist}
        />
      )}

      {showSyncModal && (
        <PlaylistSyncModal
          isOpen={showSyncModal}
          onClose={() => setShowSyncModal(false)}
          onComplete={handleSyncSuccess}
        />
      )}

      {toast && (
        <Toast
          message={toast.message}
          type={toast.type}
          onClose={() => setToast(null)}
        />
      )}
    </div>
  );
}