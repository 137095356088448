import React, { useState, useEffect, useRef } from 'react';
import { Search, Loader2, AlertCircle, X } from 'lucide-react';
import { searchTracks, getTrackById, extractSpotifyId, type SpotifyTrack } from '../utils/spotify';
import useDebounce from '../hooks/useDebounce';

interface TrackSearchProps {
  onTrackSelect: (track: SpotifyTrack) => void;
  initialUrl?: string;
  selectedTrack: SpotifyTrack | null;
  onClearTrack: () => void;
}

export function TrackSearch({ onTrackSelect, initialUrl, selectedTrack, onClearTrack }: TrackSearchProps) {
  const [query, setQuery] = useState('');
  const [tracks, setTracks] = useState<SpotifyTrack[]>([]);
  const [loading, setLoading] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const debouncedQuery = useDebounce(query, 300);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        setShowResults(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    async function fetchTracks() {
      if (!debouncedQuery.trim()) {
        setTracks([]);
        return;
      }

      setLoading(true);
      setError(null);
      try {
        const results = await searchTracks(debouncedQuery);
        if (results.length === 0) {
          setError('No tracks found');
        }
        setTracks(results);
        setShowResults(true);
      } catch (error) {
        console.error('Failed to fetch tracks:', error);
        setError('Failed to search tracks. Please try again.');
      } finally {
        setLoading(false);
      }
    }

    fetchTracks();
  }, [debouncedQuery]);

  useEffect(() => {
    async function fetchInitialTrack() {
      if (initialUrl) {
        const trackId = extractSpotifyId(initialUrl, 'track');
        if (trackId) {
          setLoading(true);
          setError(null);
          try {
            const track = await getTrackById(trackId);
            if (track) {
              onTrackSelect(track);
            } else {
              setError('Track not found');
            }
          } catch (error) {
            console.error('Failed to fetch initial track:', error);
            setError('Failed to load track. Please try again.');
          } finally {
            setLoading(false);
          }
        }
      }
    }

    fetchInitialTrack();
  }, [initialUrl, onTrackSelect]);

  const handleTrackSelect = async (track: SpotifyTrack) => {
    onTrackSelect(track);
    setShowResults(false);
    setQuery('');
    setError(null);
  };

  const handleInputChange = async (value: string) => {
    setQuery(value);
    setError(null);

    const trackId = extractSpotifyId(value, 'track');
    if (trackId) {
      setLoading(true);
      try {
        const track = await getTrackById(trackId);
        if (track) {
          handleTrackSelect(track);
        } else {
          setError('Invalid Spotify URL or track not found');
        }
      } catch (error) {
        console.error('Failed to fetch track:', error);
        setError('Failed to load track. Please try again.');
      } finally {
        setLoading(false);
      }
    }
  };

  const handleClearTrack = (e: React.MouseEvent) => {
    e.preventDefault(); // Prevent form submission
    e.stopPropagation(); // Prevent event from bubbling up
    onClearTrack();
    setQuery('');
    setError(null);
    setShowResults(false);
    setTimeout(() => {
      const input = wrapperRef.current?.querySelector('input');
      if (input) {
        input.focus();
      }
    }, 0);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    // Prevent form submission on Enter key
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  if (selectedTrack) {
    return (
      <div className="mt-3 p-3 bg-gray-50 rounded-lg flex items-center gap-4 group relative">
        <img
          src={selectedTrack.album.images[selectedTrack.album.images.length - 1]?.url}
          alt={selectedTrack.album.name}
          className="w-16 h-16 object-cover rounded-md"
        />
        <div className="flex-1">
          <h3 className="font-medium text-gray-900">{selectedTrack.name}</h3>
          <p className="text-sm text-gray-600">
            {selectedTrack.artists.map(a => a.name).join(', ')}
          </p>
          <p className="text-xs text-gray-500">{selectedTrack.album.name}</p>
        </div>
        <button
          onClick={handleClearTrack}
          className="absolute top-2 right-2 p-1 rounded-full bg-gray-200 hover:bg-gray-300 transition-colors"
          aria-label="Clear selection"
        >
          <X className="w-4 h-4 text-gray-600" />
        </button>
      </div>
    );
  }

  return (
    <div ref={wrapperRef} className="relative">
      <div className="relative">
        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
        <input
          type="text"
          placeholder="Search for your track or paste Spotify URL..."
          value={query}
          onChange={(e) => handleInputChange(e.target.value)}
          onFocus={() => setShowResults(true)}
          onKeyDown={handleKeyDown}
          className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent"
        />
        {loading && (
          <Loader2 className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5 animate-spin" />
        )}
      </div>

      {error && (
        <div className="mt-2 text-sm text-red-600 flex items-center gap-1">
          <AlertCircle className="w-4 h-4" />
          {error}
        </div>
      )}

      {showResults && (tracks.length > 0 || loading) && (
        <div className="absolute z-50 w-full mt-1 bg-white rounded-lg shadow-lg border border-gray-200 max-h-80 overflow-y-auto">
          {tracks.map((track) => (
            <button
              key={track.id}
              onClick={(e) => {
                e.preventDefault();
                handleTrackSelect(track);
              }}
              className="w-full p-3 hover:bg-gray-50 flex items-center gap-3 border-b border-gray-100 last:border-0"
            >
              <img
                src={track.album.images[track.album.images.length - 1]?.url}
                alt={track.album.name}
                className="w-10 h-10 object-cover rounded"
              />
              <div className="flex-1 text-left">
                <p className="font-medium text-gray-900 line-clamp-1">{track.name}</p>
                <p className="text-sm text-gray-500 line-clamp-1">
                  {track.artists.map(a => a.name).join(', ')}
                </p>
              </div>
            </button>
          ))}
          
          {loading && (
            <div className="p-4 text-center text-gray-500">
              <Loader2 className="w-6 h-6 animate-spin mx-auto" />
              <p className="mt-2">Searching tracks...</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
}