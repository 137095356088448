import React, { useState } from 'react';
import { X, Loader2, AlertCircle, Link, Music2, User, Users } from 'lucide-react';
import { useGenres } from '../hooks/useGenres';
import { extractSpotifyId, getPlaylistDetails } from '../utils/spotify';

interface PlaylistFormProps {
  playlist?: any;
  onClose: () => void;
  onSuccess: (playlist: any) => void;
}

export function PlaylistForm({ playlist, onClose, onSuccess }: PlaylistFormProps) {
  const { genres, loading: loadingGenres } = useGenres();
  const [formData, setFormData] = useState({
    spotifyUrl: playlist ? `https://open.spotify.com/playlist/${playlist.spotify_id}` : '',
    name: playlist?.name || '',
    description: playlist?.description || '',
    imageUrl: playlist?.image_url || '',
    genre: playlist?.genre_id || '',
    subGenres: playlist?.sub_genre_ids || [],
    followers: playlist?.followers || 0,
    curatorName: playlist?.curator_name || '',
    curatorProfileUrl: playlist?.curator_profile_url || '',
    featured: playlist?.featured || false
  });

  const [fetchingSpotify, setFetchingSpotify] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const selectedGenre = genres.find(g => g.id === formData.genre);

  const handleSpotifyUrlChange = async (url: string) => {
    setFormData(prev => ({ ...prev, spotifyUrl: url }));
    if (!url || playlist) return; // Skip fetch if editing existing playlist

    setFetchingSpotify(true);
    setError(null);

    try {
      const playlistId = extractSpotifyId(url, 'playlist');
      if (!playlistId) {
        throw new Error('Invalid Spotify playlist URL');
      }

      const spotifyData = await getPlaylistDetails(playlistId);
      if (!spotifyData) {
        throw new Error('Playlist not found on Spotify');
      }

      setFormData(prev => ({
        ...prev,
        name: spotifyData.name,
        description: spotifyData.description || prev.description,
        imageUrl: spotifyData.images[0]?.url || prev.imageUrl,
        followers: spotifyData.followers?.total || 0,
        curatorName: spotifyData.owner?.display_name || prev.curatorName,
        curatorProfileUrl: spotifyData.owner?.external_urls?.spotify || prev.curatorProfileUrl
      }));
    } catch (err) {
      console.error('Failed to fetch Spotify data:', err);
      setError(err instanceof Error ? err.message : 'Failed to fetch playlist data from Spotify');
    } finally {
      setFetchingSpotify(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      // Validate required fields
      if (!formData.spotifyUrl || !formData.name || !formData.genre) {
        throw new Error('Please fill in all required fields');
      }

      // Validate genre UUID
      if (!formData.genre.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/)) {
        throw new Error('Invalid genre selected');
      }

      const playlistId = extractSpotifyId(formData.spotifyUrl, 'playlist');
      if (!playlistId) {
        throw new Error('Invalid Spotify playlist URL');
      }

      const data = {
        spotify_id: playlistId,
        name: formData.name,
        description: formData.description,
        image_url: formData.imageUrl,
        genre_id: formData.genre,
        sub_genre_ids: formData.subGenres,
        followers: formData.followers,
        curator_name: formData.curatorName || null,
        curator_profile_url: formData.curatorProfileUrl || null,
        featured: formData.featured
      };

      onSuccess(data);
    } catch (err) {
      console.error('Failed to save playlist:', err);
      setError(err instanceof Error ? err.message : 'Failed to save playlist');
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-xl max-w-2xl w-full max-h-[90vh] overflow-y-auto">
        <div className="p-6 border-b border-gray-200">
          <div className="flex justify-between items-center">
            <h2 className="text-xl font-bold text-gray-900">
              {playlist ? 'Edit Playlist' : 'Add New Playlist'}
            </h2>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-500 transition-colors"
            >
              <X className="w-6 h-6" />
            </button>
          </div>
        </div>

        <form onSubmit={handleSubmit} className="p-6 space-y-4">
          <div>
            <label className="flex gap-2 text-sm font-medium text-gray-700 mb-1">
              <Link className="w-4 h-4" />
              Spotify Playlist URL
            </label>
            {playlist ? (
              <input
                type="text"
                value={formData.spotifyUrl}
                readOnly
                className="w-full p-2 border border-gray-300 rounded-lg bg-gray-50 text-gray-500"
              />
            ) : (
              <input
                type="text"
                value={formData.spotifyUrl}
                onChange={(e) => handleSpotifyUrlChange(e.target.value)}
                placeholder="https://open.spotify.com/playlist/..."
                className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent"
                required
              />
            )}
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="flex gap-2 text-sm font-medium text-gray-700 mb-1">
                <Music2 className="w-4 h-4" />
                Name
              </label>
              <input
                type="text"
                value={formData.name}
                onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent"
                required
              />
            </div>

            <div>
              <label className="flex gap-2 text-sm font-medium text-gray-700 mb-1">
                <Users className="w-4 h-4" />
                Followers
              </label>
              <input
                type="number"
                value={formData.followers}
                onChange={(e) => setFormData(prev => ({ ...prev, followers: parseInt(e.target.value) || 0 }))}
                className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent"
                required
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Description
            </label>
            <textarea
              value={formData.description}
              onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
              rows={3}
              className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Genre
            </label>
            <select
              value={formData.genre}
              onChange={(e) => setFormData(prev => ({ 
                ...prev, 
                genre: e.target.value,
                subGenres: [] // Reset sub-genres when genre changes
              }))}
              className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent"
              required
              disabled={loadingGenres}
            >
              <option value="">Select a genre</option>
              {genres.map(genre => (
                <option key={genre.id} value={genre.id}>
                  {genre.name}
                </option>
              ))}
            </select>
          </div>

          {selectedGenre && selectedGenre.subGenres.length > 0 && (
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Sub-genres
              </label>
              <div className="space-y-2">
                {selectedGenre.subGenres.map(subGenre => (
                  <label key={subGenre.id} className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      checked={formData.subGenres.includes(subGenre.id)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setFormData(prev => ({
                            ...prev,
                            subGenres: [...prev.subGenres, subGenre.id]
                          }));
                        } else {
                          setFormData(prev => ({
                            ...prev,
                            subGenres: prev.subGenres.filter(id => id !== subGenre.id)
                          }));
                        }
                      }}
                      className="rounded border-gray-300 text-green-600 focus:ring-green-500"
                    />
                    <span className="text-sm text-gray-700">{subGenre.name}</span>
                  </label>
                ))}
              </div>
            </div>
          )}

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="flex gap-2 text-sm font-medium text-gray-700 mb-1">
                <User className="w-4 h-4" />
                Curator Name
              </label>
              <input
                type="text"
                value={formData.curatorName}
                onChange={(e) => setFormData(prev => ({ ...prev, curatorName: e.target.value }))}
                className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent"
              />
            </div>

            <div>
              <label className="flex gap-2 text-sm font-medium text-gray-700 mb-1">
                <Link className="w-4 h-4" />
                Curator Profile URL
              </label>
              <input
                type="text"
                value={formData.curatorProfileUrl}
                onChange={(e) => setFormData(prev => ({ ...prev, curatorProfileUrl: e.target.value }))}
                className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent"
              />
            </div>
          </div>

          <div>
            <label className="flex items-center gap-2">
              <input
                type="checkbox"
                checked={formData.featured}
                onChange={(e) => setFormData(prev => ({ ...prev, featured: e.target.checked }))}
                className="rounded border-gray-300 text-green-600 focus:ring-green-500"
              />
              <span className="text-sm font-medium text-gray-700">Featured Playlist</span>
            </label>
            <p className="text-sm text-gray-500 mt-1">
              Featured playlists appear first in the default sort order
            </p>
          </div>

          {error && (
            <div className="flex items-center gap-2 text-red-600 text-sm">
              <AlertCircle className="w-4 h-4 flex-shrink-0" />
              <p>{error}</p>
            </div>
          )}

          <div className="flex justify-end gap-3 pt-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg transition-colors"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={loading || fetchingSpotify}
              className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 disabled:bg-gray-400 disabled:cursor-not-allowed flex items-center gap-2"
            >
              {(loading || fetchingSpotify) && (
                <Loader2 className="w-5 h-5 animate-spin" />
              )}
              {loading ? 'Saving...' : 'Save Playlist'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}