import React, { useState, useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { FilterBar } from '../components/FilterBar';
import { PlaylistCard } from '../components/PlaylistCard';
import { SubmissionModal } from '../components/SubmissionModal';
import { Toast } from '../components/Toast';
import { SEO } from '../components/SEO';
import { X, Loader2 } from 'lucide-react';
import { usePlaylists } from '../hooks/usePlaylists';
import { getLastSubmissionData, resetAllStorageData } from '../utils/storage';
import { scrollManager } from '../utils/scrollManager';

interface FilterState {
  search: string;
  genre: string;
  subGenre: string;
  sortBy: 'default' | 'followers-high' | 'followers-low';
}

export function HomePage() {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState<FilterState>(() => {
    // Check for filters in location state (from genre selection)
    const stateFilters = (location.state as { filters?: Partial<FilterState> })?.filters;
    
    const savedFilters = sessionStorage.getItem('playlist_filters');
    if (savedFilters && !stateFilters?.fromSubmission) {
      return JSON.parse(savedFilters);
    }
    
    if (stateFilters) {
      // Clear location state after using it
      window.history.replaceState({}, '');
      return {
        search: '',
        genre: stateFilters.genre || '',
        subGenre: stateFilters.subGenre || '',
        sortBy: 'default'
      };
    }
    
    return {
      search: '',
      genre: '',
      subGenre: '',
      sortBy: 'default'
    };
  });

  const { 
    playlists, 
    loading, 
    error, 
    hasMore, 
    loadMore,
    currentPage,
    totalCount,
    itemsPerPage 
  } = usePlaylists({
    ...filters,
    // Ensure we're passing the exact genre name and sub-genre name
    genre: filters.genre || undefined,
    subGenre: filters.subGenre || undefined
  });

  const [selectedPlaylist, setSelectedPlaylist] = useState<typeof playlists[0] | null>(null);
  const lastSubmission = getLastSubmissionData();
  const [initialLoad, setInitialLoad] = useState(true);

  // Handle scroll position and initial load
  useEffect(() => {
    if (!loading && playlists.length > 0 && initialLoad) {
      const stateFilters = (location.state as { filters?: Partial<FilterState> })?.filters;
      
      if (stateFilters?.fromSubmission) {
        // If coming from submission flow, scroll to top
        window.scrollTo(0, 0);
      } else {
        // Otherwise restore scroll position
        scrollManager.restorePosition(location.pathname, setSearchParams);
      }
      setInitialLoad(false);
    }
  }, [loading, playlists.length, location.pathname, location.state, setSearchParams, initialLoad]);

  // Save filters to session storage
  useEffect(() => {
    sessionStorage.setItem('playlist_filters', JSON.stringify(filters));
  }, [filters]);

  // Calculate values for SEO meta tags
  const startIndex = (currentPage - 1) * itemsPerPage + 1;
  const endIndex = Math.min(currentPage * itemsPerPage, totalCount);

  return (
    <>
      <SEO />

      <main>
        <div className="bg-gradient-to-b from-green-50 to-white">
          <div className="container mx-auto px-4 py-12">
            <div className="max-w-4xl mx-auto text-center">
              <h1 className="text-4xl font-bold text-gray-900 mb-4">
                Free Spotify Playlist Submissions - No Signup
              </h1>
              <h2 className="text-xl text-gray-600">
                Reach Thousands of Independent Playlist Curators for Free
              </h2>
            </div>
          </div>
        </div>

        <div className="container mx-auto px-4 pb-12">
          <FilterBar filters={filters} onFilterChange={setFilters} />
          
          {lastSubmission?.lastTrack && (
            <div className="mb-6 flex items-center justify-between bg-white p-4 shadow-sm rounded-lg max-w-[1400px] mx-auto">
              <div className="flex items-center gap-4">
                <img
                  src={lastSubmission.lastTrack.album.images[2]?.url}
                  alt={lastSubmission.lastTrack.name}
                  className="w-12 h-12 object-cover rounded-md"
                />
                <div>
                  <p className="font-medium">{lastSubmission.lastTrack.name}</p>
                  <p className="text-sm text-gray-600">
                    {lastSubmission.lastTrack.artists[0]?.name}
                  </p>
                </div>
              </div>
              <button
                onClick={() => {
                  resetAllStorageData();
                  window.location.reload();
                }}
                className="p-2 hover:bg-gray-100 rounded-full transition-colors"
                title="Clear selected track"
              >
                <X className="w-5 h-5 text-gray-500" />
              </button>
            </div>
          )}

          <div className="max-w-[1400px] mx-auto" style={{ minHeight: '800px' }}>
            {playlists.length > 0 && (
              <div className="text-sm text-gray-600 mb-6 mt-6">
                Showing {startIndex}-{endIndex} of {totalCount} playlists
              </div>
            )}

            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
              {playlists.map((playlist, index) => (
                <PlaylistCard
                  key={`${playlist.id}-${index}`}
                  playlist={playlist}
                  onSubmit={(id) => setSelectedPlaylist(playlists.find(p => p.id === id) || null)}
                  priority={index < 4}
                />
              ))}
            </div>

            {loading && (
              <div className="flex items-center justify-center py-12">
                <Loader2 className="w-8 h-8 text-green-500 animate-spin" />
              </div>
            )}

            {error && (
              <div className="text-center py-12">
                <p className="text-red-500">Failed to load playlists. Please try again.</p>
              </div>
            )}

            {!loading && !error && playlists.length === 0 && (
              <div className="text-center py-12">
                <p className="text-gray-500 text-lg">
                  No playlists found matching your criteria.
                </p>
              </div>
            )}

            {hasMore && !loading && (
              <div className="text-center mt-8">
                <button
                  onClick={loadMore}
                  className="px-6 py-3 bg-green-500 text-white rounded-lg hover:bg-green-600 transition-colors"
                >
                  Load More Playlists
                </button>
              </div>
            )}
          </div>
        </div>

        {selectedPlaylist && (
          <SubmissionModal
            playlist={selectedPlaylist}
            onClose={() => setSelectedPlaylist(null)}
            onSubmit={() => {
              setSelectedPlaylist(null);
            }}
          />
        )}
      </main>
    </>
  );
}