import React, { useState, useCallback } from 'react';
import { Upload, X, AlertCircle, Loader2, CheckCircle2, AlertTriangle } from 'lucide-react';
import { parseCSV } from '../utils/csv-parser';
import { playlistsService } from '../services/playlists.service';

interface PlaylistImportProps {
  onSuccess: () => void;
}

interface ImportProgress {
  stage: 'reading' | 'processing' | 'importing';
  current: number;
  total: number;
  processed: number;
  skipped: number;
  failed: number;
  unmatchedCategories: Set<string>;
  recentErrors: { row: number; message: string }[];
}

export function PlaylistImport({ onSuccess }: PlaylistImportProps) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [progress, setProgress] = useState<ImportProgress | null>(null);

  const updateProgress = (updates: Partial<ImportProgress>) => {
    setProgress(prev => prev ? { ...prev, ...updates } : null);
  };

  const processFile = async (file: File) => {
    if (!file.name.endsWith('.csv')) {
      setError('Please provide a CSV file');
      return;
    }

    setLoading(true);
    setError(null);
    setProgress({
      stage: 'reading',
      current: 0,
      total: 1,
      processed: 0,
      skipped: 0,
      failed: 0,
      unmatchedCategories: new Set(),
      recentErrors: []
    });

    try {
      // Read and parse CSV
      const text = await file.text();
      const playlists = parseCSV(text);

      // Update progress for processing stage
      updateProgress({
        stage: 'processing',
        current: 0,
        total: playlists.length
      });

      // Import playlists with progress callback
      const result = await playlistsService.importPlaylists(playlists, (progress) => {
        updateProgress({
          stage: 'importing',
          current: progress.current,
          processed: progress.processed,
          skipped: progress.skipped,
          failed: progress.failed,
          unmatchedCategories: progress.unmatchedCategories,
          recentErrors: progress.recentErrors.slice(-5) // Keep last 5 errors
        });
      });

      // Show final results
      const message = [
        `Successfully imported ${result.successCount} playlists.`,
        result.skipCount > 0 ? `Skipped ${result.skipCount} duplicate playlists.` : '',
        result.unmatchedCategories.length > 0 ? `${result.unmatchedCategories.length} categories could not be matched.` : '',
        result.errors?.length > 0 ? `${result.errors.length} playlists failed to import.` : ''
      ].filter(Boolean).join(' ');

      if (result.successCount > 0) {
        onSuccess();
      }

      setError(message);
    } catch (err) {
      console.error('Import failed:', err);
      setError(err instanceof Error ? err.message : 'Failed to import playlists');
    } finally {
      setLoading(false);
      setProgress(null);
    }
  };

  const handleDrop = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) processFile(file);
  }, []);

  const handleFileSelect = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) processFile(file);
    e.target.value = ''; // Reset input
  }, []);

  const renderProgressStage = () => {
    if (!progress) return null;

    const percentage = Math.round((progress.current / progress.total) * 100);
    const stageName = {
      reading: 'Reading file',
      processing: 'Processing playlists',
      importing: 'Importing playlists'
    }[progress.stage];

    return (
      <div className="space-y-4">
        <div className="flex items-center justify-between text-sm text-gray-600">
          <span>{stageName}</span>
          <span>{percentage}%</span>
        </div>

        <div className="w-full h-2 bg-gray-200 rounded-full overflow-hidden">
          <div 
            className="h-full bg-green-500 transition-all duration-300"
            style={{ width: `${percentage}%` }}
          />
        </div>

        <div className="grid grid-cols-3 gap-4 text-sm">
          <div className="flex items-center gap-2 text-green-600">
            <CheckCircle2 className="w-4 h-4" />
            <span>{progress.processed} processed</span>
          </div>
          <div className="flex items-center gap-2 text-yellow-600">
            <AlertTriangle className="w-4 h-4" />
            <span>{progress.skipped} skipped</span>
          </div>
          <div className="flex items-center gap-2 text-red-600">
            <AlertCircle className="w-4 h-4" />
            <span>{progress.failed} failed</span>
          </div>
        </div>

        {progress.recentErrors.length > 0 && (
          <div className="mt-4 space-y-2">
            <h4 className="text-sm font-medium text-gray-700">Recent Issues:</h4>
            <div className="bg-red-50 rounded-lg p-3 space-y-2">
              {progress.recentErrors.map((error, index) => (
                <div key={index} className="text-sm text-red-600 flex items-start gap-2">
                  <AlertCircle className="w-4 h-4 flex-shrink-0 mt-0.5" />
                  <span>Row {error.row}: {error.message}</span>
                </div>
              ))}
            </div>
          </div>
        )}

        {progress.unmatchedCategories.size > 0 && (
          <div className="mt-4 space-y-2">
            <h4 className="text-sm font-medium text-gray-700">Unmatched Categories:</h4>
            <div className="bg-yellow-50 rounded-lg p-3">
              <div className="flex flex-wrap gap-2">
                {Array.from(progress.unmatchedCategories).map((category, index) => (
                  <span key={index} className="inline-flex items-center px-2 py-1 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                    {category}
                  </span>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-sm">
      <h2 className="text-lg font-semibold mb-4">Import Playlists</h2>

      <div
        onDragOver={(e) => e.preventDefault()}
        onDrop={handleDrop}
        className={`
          border-2 border-dashed rounded-lg p-8 text-center transition-colors
          ${loading ? 'opacity-50 cursor-not-allowed' : 'hover:border-green-500 cursor-pointer'}
        `}
      >
        {loading ? (
          <div className="space-y-6">
            <Loader2 className="w-8 h-8 text-green-500 animate-spin mx-auto" />
            {renderProgressStage()}
          </div>
        ) : (
          <>
            <Upload className="w-8 h-8 text-gray-400 mx-auto mb-3" />
            <p className="text-gray-600 mb-2">
              Drag and drop your CSV file here, or click to select
            </p>
            <input
              type="file"
              accept=".csv"
              onChange={handleFileSelect}
              className="hidden"
              id="file-upload"
              disabled={loading}
            />
            <label
              htmlFor="file-upload"
              className="inline-block px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition-colors cursor-pointer"
            >
              Select File
            </label>
          </>
        )}
      </div>

      {error && (
        <div className={`mt-4 p-3 rounded-lg flex items-center gap-2 ${
          error.includes('Successfully') 
            ? 'bg-green-50 text-green-700' 
            : 'bg-red-50 text-red-700'
        }`}>
          <AlertCircle className="w-5 h-5 flex-shrink-0" />
          <p className="flex-1">{error}</p>
          <button onClick={() => setError(null)} className="ml-auto">
            <X className="w-5 h-5" />
          </button>
        </div>
      )}

      <div className="mt-4 text-sm text-gray-500">
        <p className="font-medium mb-2">CSV Format Requirements:</p>
        <ul className="list-disc list-inside space-y-1">
          <li>File must be in CSV format</li>
          <li>First row must contain headers</li>
          <li>Required columns: Cover URL, Name, URL, Category, Sub Genres, Description, Curator, Curator URL, Followers, Tracks</li>
          <li>Sub Genres can contain multiple values separated by semicolons (;)</li>
          <li>Values containing commas must be enclosed in quotes</li>
        </ul>
        
        <p className="mt-4 font-medium mb-2">Example CSV format:</p>
        <pre className="bg-gray-50 p-2 rounded text-xs overflow-x-auto">
          Cover URL,Name,URL,Category,Sub Genres,Description,Curator,Curator URL,Followers,Tracks{'\n'}
          "https://example.com/cover.jpg","Chill Vibes","https://open.spotify.com/playlist/37i9dQZF1DX8Uebhn9wzrS","Electronic","Lo-Fi; Ambient; Downtempo","Best chill electronic music","DJ Cool","https://open.spotify.com/user/djcool",50000,100
        </pre>
      </div>
    </div>
  );
}