import { createClient } from '@supabase/supabase-js';
import type { Database } from '../services/database.types';

const supabaseUrl = 'https://ftwlzdhkzqfuosdorgjt.supabase.co';
const supabaseAnonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImZ0d2x6ZGhrenFmdW9zZG9yZ2p0Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzE1Mjc0MzksImV4cCI6MjA0NzEwMzQzOX0.msaWtaLGNMDiyuxIOFDwULWee0nqcM7p_LAYWB14j1k';

if (!supabaseUrl || !supabaseAnonKey) {
  throw new Error('Missing Supabase environment variables');
}

export const supabase = createClient<Database>(supabaseUrl, supabaseAnonKey, {
  auth: {
    persistSession: true,
    autoRefreshToken: true,
    detectSessionInUrl: true
  }
});

// Add error handling for common Supabase errors
export function handleSupabaseError(error: any): Error {
  if (!error) return new Error('Unknown error occurred');

  // Handle specific Supabase error codes
  switch (error.code) {
    case 'PGRST103':
      return new Error('No more results available');
    case 'PGRST301':
      return new Error('Row level security violation');
    case '23505':
      return new Error('This record already exists');
    case '42P01':
      return new Error('Table not found');
    case '42703':
      return new Error('Column not found');
    default:
      if (error.message) {
        return new Error(error.message);
      }
      return new Error('An unexpected error occurred');
  }
}