import { createClient } from '@supabase/supabase-js';
import { cleanDescription } from './description-cleaner';
import type { Database } from '../services/database.types';

const CLIENT_ID = '76b2d4d1d7bf4e8cb8ad64047c0c5851';
const CLIENT_SECRET = '1c3baa4d0f0044d5a1e3bb830cfe2214';
const RATE_LIMIT_WINDOW = 15000; // 15 seconds
let lastRequestTime = 0;

interface SpotifyToken {
  access_token: string;
  expires_at: number;
}

export interface SpotifyTrack {
  id: string;
  name: string;
  artists: { name: string }[];
  album: {
    name: string;
    images: { url: string }[];
  };
  external_urls: {
    spotify: string;
  };
}

export interface SpotifyPlaylist {
  id: string;
  name: string;
  description: string;
  images: { url: string }[];
  followers: { total: number };
  owner: {
    id: string;
    display_name: string;
    external_urls: {
      spotify: string;
    };
  };
  tracks: {
    total: number;
  };
}

function cleanSpotifyUrl(url: string): string {
  try {
    // Handle Spotify URI format
    if (url.startsWith('spotify:')) {
      const parts = url.split(':');
      return `https://open.spotify.com/${parts[1]}/${parts[2]}`;
    }

    // Remove query parameters and hash
    const urlObj = new URL(url);
    return `https://open.spotify.com${urlObj.pathname}`;
  } catch (error) {
    return url; // Return original if URL parsing fails
  }
}

export function formatSpotifyUrl(type: 'track' | 'playlist' | 'user', id: string): string {
  return `https://open.spotify.com/${type}/${id}`;
}

export function extractSpotifyId(url: string, type: 'track' | 'playlist' | 'user'): string | null {
  if (!url) return null;
  
  try {
    // Clean the URL first
    const cleanedUrl = cleanSpotifyUrl(url);

    // Handle both URL and URI formats
    const patterns = [
      new RegExp(`${type}/([a-zA-Z0-9]+)`), // URL format
      new RegExp(`${type}:([a-zA-Z0-9]+)`)   // URI format
    ];

    for (const pattern of patterns) {
      const match = cleanedUrl.match(pattern);
      if (match && match[1]) {
        return match[1];
      }
    }

    return null;
  } catch (error) {
    console.error('Failed to extract ID:', error);
    return null;
  }
}

async function getAccessToken(): Promise<string> {
  try {
    const storedToken = localStorage.getItem('spotify_token');
    if (storedToken) {
      const token: SpotifyToken = JSON.parse(storedToken);
      if (token.expires_at > Date.now()) {
        return token.access_token;
      }
    }

    const response = await fetch('https://accounts.spotify.com/api/token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Basic ${btoa(`${CLIENT_ID}:${CLIENT_SECRET}`)}`,
      },
      body: 'grant_type=client_credentials',
    });

    if (!response.ok) {
      throw new Error(`Failed to get access token: ${response.status} ${response.statusText}`);
    }

    const data = await response.json();
    const token: SpotifyToken = {
      access_token: data.access_token,
      expires_at: Date.now() + data.expires_in * 1000,
    };

    localStorage.setItem('spotify_token', JSON.stringify(token));
    return token.access_token;
  } catch (error) {
    console.error('Failed to get Spotify access token:', error);
    throw new Error('Failed to authenticate with Spotify');
  }
}

async function rateLimitRequest<T>(request: () => Promise<T>): Promise<T> {
  const now = Date.now();
  const timeSinceLastRequest = now - lastRequestTime;

  if (timeSinceLastRequest < RATE_LIMIT_WINDOW) {
    await new Promise(resolve => setTimeout(resolve, RATE_LIMIT_WINDOW - timeSinceLastRequest));
  }

  lastRequestTime = Date.now();
  return request();
}

export async function getPlaylistDetails(playlistId: string): Promise<SpotifyPlaylist | null> {
  try {
    return await rateLimitRequest(async () => {
      const token = await getAccessToken();
      const response = await fetch(
        `https://api.spotify.com/v1/playlists/${playlistId}?fields=id,name,description,images,followers,owner(id,display_name,external_urls),tracks(total)`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        if (response.status === 404) {
          return null;
        }
        if (response.status === 429) {
          throw new Error('Rate limit exceeded. Please try again later.');
        }
        throw new Error(`Failed to fetch playlist: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      
      // Clean description and extract email
      const { description: cleanedDescription, extractedEmail } = cleanDescription(data.description || '');
      
      return {
        ...data,
        description: cleanedDescription,
        curator_email: extractedEmail
      };
    });
  } catch (error) {
    console.error('Failed to fetch playlist:', error);
    throw error instanceof Error ? error : new Error('Failed to fetch playlist data');
  }
}

export async function searchTracks(query: string): Promise<SpotifyTrack[]> {
  if (!query.trim()) return [];
  
  try {
    return await rateLimitRequest(async () => {
      const token = await getAccessToken();
      const response = await fetch(
        `https://api.spotify.com/v1/search?q=${encodeURIComponent(query)}&type=track&limit=5`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        if (response.status === 429) {
          throw new Error('Rate limit exceeded. Please try again later.');
        }
        throw new Error(`Search failed: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      if (!data.tracks?.items) {
        throw new Error('Invalid response format from Spotify API');
      }

      return data.tracks.items;
    });
  } catch (error) {
    console.error('Failed to search tracks:', error);
    throw error instanceof Error ? error : new Error('Failed to search tracks');
  }
}

export async function getTrackById(trackId: string): Promise<SpotifyTrack | null> {
  if (!trackId) return null;

  try {
    return await rateLimitRequest(async () => {
      const token = await getAccessToken();
      const response = await fetch(
        `https://api.spotify.com/v1/tracks/${encodeURIComponent(trackId)}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        if (response.status === 404) {
          return null;
        }
        if (response.status === 429) {
          throw new Error('Rate limit exceeded. Please try again later.');
        }
        throw new Error(`Failed to fetch track: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      if (!data.id || !data.name || !data.artists || !data.album) {
        throw new Error('Invalid track data received from Spotify API');
      }

      return data;
    });
  } catch (error) {
    console.error('Failed to fetch track:', error);
    throw error instanceof Error ? error : new Error('Failed to fetch track data');
  }
}