import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Music2, Edit, Trash2, ChevronRight, GripVertical } from 'lucide-react';

interface SubGenre {
  id: string;
  name: string;
  description: string;
}

interface SortableGenreProps {
  id: string;
  name: string;
  description: string;
  subGenres: SubGenre[];
  onEdit: () => void;
  onDelete: () => void;
}

export function SortableGenre({ id, name, description, subGenres, onEdit, onDelete }: SortableGenreProps) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 1 : undefined,
    opacity: isDragging ? 0.5 : undefined,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className={`bg-white p-4 rounded-lg shadow-sm ${isDragging ? 'shadow-lg' : ''}`}
    >
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center gap-3">
          <button
            className="p-2 text-gray-400 hover:text-gray-600 cursor-grab active:cursor-grabbing"
            {...attributes}
            {...listeners}
          >
            <GripVertical className="w-5 h-5" />
          </button>
          <div className="p-2 bg-green-50 rounded-lg">
            <Music2 className="w-5 h-5 text-green-600" />
          </div>
          <div>
            <h3 className="text-lg font-medium">{name}</h3>
            <p className="text-sm text-gray-500">
              {subGenres.length} sub-genres
            </p>
          </div>
        </div>
        <div className="flex items-center gap-2">
          <button
            onClick={onEdit}
            className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
            title="Edit genre"
          >
            <Edit className="w-5 h-5 text-gray-600" />
          </button>
          <button
            onClick={onDelete}
            className="p-2 hover:bg-red-50 rounded-lg transition-colors"
            title="Delete genre"
          >
            <Trash2 className="w-5 h-5 text-red-600" />
          </button>
        </div>
      </div>

      <p className="text-gray-600 mb-4">{description}</p>

      {subGenres.length > 0 && (
        <div className="ml-12 space-y-2">
          {subGenres.map(subGenre => (
            <div
              key={subGenre.id}
              className="flex items-start gap-2 text-gray-600"
            >
              <ChevronRight className="w-4 h-4 mt-1 flex-shrink-0" />
              <div>
                <p className="font-medium text-sm">{subGenre.name}</p>
                <p className="text-sm text-gray-500">{subGenre.description}</p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}