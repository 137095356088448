import React, { useState } from 'react';
import { X, Plus, Trash2, Loader2, AlertCircle, Edit2 } from 'lucide-react';
import { useGenres } from '../hooks/useGenres';
import { slugify } from '../utils/formatting';

interface SubGenre {
  id: string;
  name: string;
  description: string;
}

interface GenreFormProps {
  genre?: {
    id: string;
    name: string;
    description: string;
    subGenres: SubGenre[];
  } | null;
  onClose: () => void;
  onSuccess: (genre: { id: string; name: string; description: string; subGenres: SubGenre[] }) => void;
}

interface SubGenreFormData {
  name: string;
  description: string;
}

export function GenreForm({ genre, onClose, onSuccess }: GenreFormProps) {
  const [name, setName] = useState(genre?.name || '');
  const [description, setDescription] = useState(genre?.description || '');
  const [subGenres, setSubGenres] = useState<SubGenreFormData[]>(
    genre?.subGenres.map(sg => ({
      name: sg.name,
      description: sg.description
    })) || []
  );
  const [newSubGenre, setNewSubGenre] = useState<SubGenreFormData>({
    name: '',
    description: ''
  });
  const [editingSubGenreIndex, setEditingSubGenreIndex] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { createGenre, updateGenre } = useGenres();

  const validateSubGenre = (value: SubGenreFormData, index?: number) => {
    if (!value.name.trim()) {
      throw new Error('Sub-genre name cannot be empty');
    }

    if (!value.description.trim()) {
      throw new Error('Sub-genre description cannot be empty');
    }

    const slug = slugify(value.name);
    const existingSlugs = subGenres
      .filter((_, i) => i !== index)
      .map(sg => slugify(sg.name));
    
    if (existingSlugs.includes(slug)) {
      throw new Error('This sub-genre already exists');
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    try {
      if (!name.trim()) {
        throw new Error('Genre name is required');
      }

      if (!description.trim()) {
        throw new Error('Genre description is required');
      }

      // Validate all sub-genres
      const uniqueSlugs = new Set(subGenres.map(sg => slugify(sg.name)));
      if (uniqueSlugs.size !== subGenres.length) {
        throw new Error('Duplicate sub-genres are not allowed');
      }

      const data = {
        name: name.trim(),
        description: description.trim(),
        subGenres: subGenres.map(sg => ({
          name: sg.name.trim(),
          description: sg.description.trim()
        }))
      };

      let result;
      if (genre) {
        result = await updateGenre(genre.id, data);
      } else {
        result = await createGenre(data);
      }

      onSuccess(result);
    } catch (err) {
      console.error('Failed to save genre:', err);
      setError(err instanceof Error ? err.message : 'Failed to save genre');
    } finally {
      setLoading(false);
    }
  };

  const addSubGenre = () => {
    try {
      validateSubGenre(newSubGenre);
      setSubGenres([...subGenres, { ...newSubGenre }]);
      setNewSubGenre({ name: '', description: '' });
      setError(null);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Invalid sub-genre');
    }
  };

  const updateSubGenre = (index: number, data: Partial<SubGenreFormData>) => {
    try {
      const updatedSubGenre = { ...subGenres[index], ...data };
      validateSubGenre(updatedSubGenre, index);
      
      const newSubGenres = [...subGenres];
      newSubGenres[index] = updatedSubGenre;
      setSubGenres(newSubGenres);
      setEditingSubGenreIndex(null);
      setError(null);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Invalid sub-genre');
    }
  };

  const removeSubGenre = (index: number) => {
    setSubGenres(subGenres.filter((_, i) => i !== index));
    setError(null);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-xl max-w-lg w-full max-h-[90vh] overflow-y-auto">
        <div className="p-6 border-b border-gray-200">
          <div className="flex justify-between items-center">
            <h2 className="text-xl font-bold text-gray-900">
              {genre ? 'Edit Genre' : 'Add New Genre'}
            </h2>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-500 transition-colors"
            >
              <X className="w-6 h-6" />
            </button>
          </div>
        </div>

        <form onSubmit={handleSubmit} className="p-6 space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Genre Name
            </label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent"
              placeholder="e.g., Hip Hop"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Description
            </label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent"
              placeholder="Describe this genre..."
              rows={3}
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Sub-genres
            </label>
            <div className="space-y-4 mb-4">
              <div className="grid grid-cols-1 gap-3">
                <input
                  type="text"
                  value={newSubGenre.name}
                  onChange={(e) => setNewSubGenre(prev => ({ ...prev, name: e.target.value }))}
                  className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent"
                  placeholder="Sub-genre name"
                />
                <textarea
                  value={newSubGenre.description}
                  onChange={(e) => setNewSubGenre(prev => ({ ...prev, description: e.target.value }))}
                  className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent"
                  placeholder="Describe this sub-genre..."
                  rows={2}
                />
                <button
                  type="button"
                  onClick={addSubGenre}
                  className="w-full px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 flex items-center justify-center gap-2"
                >
                  <Plus className="w-5 h-5" />
                  Add Sub-genre
                </button>
              </div>
            </div>

            <div className="space-y-3">
              {subGenres.map((subGenre, index) => (
                <div
                  key={index}
                  className="bg-gray-50 p-3 rounded-lg space-y-2"
                >
                  {editingSubGenreIndex === index ? (
                    <div className="space-y-2">
                      <input
                        type="text"
                        value={subGenre.name}
                        onChange={(e) => updateSubGenre(index, { name: e.target.value })}
                        className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent"
                      />
                      <textarea
                        value={subGenre.description}
                        onChange={(e) => updateSubGenre(index, { description: e.target.value })}
                        className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent"
                        rows={2}
                      />
                      <div className="flex justify-end gap-2">
                        <button
                          type="button"
                          onClick={() => setEditingSubGenreIndex(null)}
                          className="px-3 py-1 text-sm text-gray-600 hover:bg-gray-200 rounded"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          onClick={() => updateSubGenre(index, subGenre)}
                          className="px-3 py-1 text-sm bg-green-500 text-white rounded hover:bg-green-600"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="flex items-center justify-between">
                        <h4 className="font-medium">{subGenre.name}</h4>
                        <div className="flex items-center gap-2">
                          <button
                            type="button"
                            onClick={() => setEditingSubGenreIndex(index)}
                            className="p-1 hover:bg-gray-200 rounded transition-colors"
                            title="Edit sub-genre"
                          >
                            <Edit2 className="w-4 h-4 text-gray-600" />
                          </button>
                          <button
                            type="button"
                            onClick={() => removeSubGenre(index)}
                            className="p-1 hover:bg-red-100 rounded transition-colors"
                            title="Remove sub-genre"
                          >
                            <Trash2 className="w-4 h-4 text-red-500" />
                          </button>
                        </div>
                      </div>
                      <p className="text-sm text-gray-600">{subGenre.description}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>

          {error && (
            <div className="flex items-center gap-2 text-red-600 text-sm">
              <AlertCircle className="w-4 h-4 flex-shrink-0" />
              <span>{error}</span>
            </div>
          )}

          <div className="flex justify-end gap-3 pt-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg transition-colors"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={loading}
              className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 disabled:bg-gray-400 disabled:cursor-not-allowed flex items-center gap-2"
            >
              {loading ? (
                <>
                  <Loader2 className="w-5 h-5 animate-spin" />
                  Saving...
                </>
              ) : (
                <>Save Genre</>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}