import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, Music2, ArrowRight } from 'lucide-react';
import { useGenres } from '../hooks/useGenres';
import { slugify } from '../utils/formatting';
import type { SpotifyTrack } from '../utils/spotify';

interface GenreSelectProps {
  selectedTrack: SpotifyTrack;
}

export function GenreSelect() {
  const navigate = useNavigate();
  const { genres, loading } = useGenres();
  const [selectedGenre, setSelectedGenre] = useState<string>('');
  const [selectedSubGenre, setSelectedSubGenre] = useState<string>('');

  const selectedGenreData = genres.find(g => g.id === selectedGenre);
  const subGenres = selectedGenreData?.subGenres || [];

  const handleContinue = () => {
    if (!selectedGenre || !selectedGenreData) return;
    
    // Find selected sub-genre data if one is selected
    const selectedSubGenreData = selectedSubGenre 
      ? subGenres.find(sg => sg.id === selectedSubGenre)
      : null;
    
    // Navigate to home with genre filters and scroll to top
    window.scrollTo(0, 0);
    navigate('/', { 
      state: { 
        filters: {
          genre: selectedGenreData.name,
          subGenre: selectedSubGenreData?.name || '',
          fromSubmission: true // Add flag to indicate coming from submission flow
        }
      },
      replace: true // Use replace to prevent back button from going to genre select
    });
  };

  return (
    <div className="container mx-auto px-4 py-8 max-w-4xl">
      <div className="space-y-8">
        <div className="text-center">
          <h1 className="text-3xl font-bold mb-2">Select Your Genre</h1>
          <p className="text-gray-600">Choose the main category that best fits your track</p>
        </div>

        <div>
          <h2 className="text-lg font-medium mb-3">Main Genre</h2>
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-3">
            {genres.map((genre) => (
              <button
                key={genre.id}
                onClick={() => {
                  setSelectedGenre(genre.id);
                  setSelectedSubGenre(''); // Reset sub-genre when main genre changes
                }}
                className={`p-4 text-center border-2 rounded-lg transition-colors ${
                  selectedGenre === genre.id
                    ? 'border-green-500 bg-green-50'
                    : 'border-gray-200 hover:border-green-500 hover:bg-green-50'
                }`}
              >
                <Music2 className="w-6 h-6 mx-auto mb-2 text-gray-600" />
                <span className="font-medium">{genre.name}</span>
              </button>
            ))}
          </div>
        </div>

        {selectedGenre && subGenres.length > 0 && (
          <div>
            <h2 className="text-lg font-medium mb-3">Sub-genre (Optional)</h2>
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-3">
              {subGenres.map((subGenre) => (
                <button
                  key={subGenre.id}
                  onClick={() => setSelectedSubGenre(subGenre.id)}
                  className={`p-3 text-center border-2 rounded-lg transition-colors ${
                    selectedSubGenre === subGenre.id
                      ? 'border-green-500 bg-green-50'
                      : 'border-gray-200 hover:border-green-500 hover:bg-green-50'
                  }`}
                >
                  <span className="font-medium">{subGenre.name}</span>
                </button>
              ))}
            </div>
          </div>
        )}

        <div className="flex justify-between items-center">
          <button
            onClick={() => navigate('/submit')}
            className="flex items-center gap-2 text-gray-600 hover:text-gray-800 transition-colors"
          >
            <ArrowLeft className="w-5 h-5" />
            Back to Track Selection
          </button>

          <button
            onClick={handleContinue}
            disabled={!selectedGenre}
            className={`flex items-center gap-2 px-6 py-3 rounded-lg font-medium transition-colors ${
              selectedGenre
                ? 'bg-green-500 text-white hover:bg-green-600'
                : 'bg-gray-200 text-gray-500 cursor-not-allowed'
            }`}
          >
            Continue to Playlists
            <ArrowRight className="w-5 h-5" />
          </button>
        </div>
      </div>
    </div>
  );
}