import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ErrorBoundary } from './components/ErrorBoundary';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { HomePage } from './pages/HomePage';
import { PlaylistPage } from './pages/PlaylistPage';
import { SubmissionFlow } from './pages/SubmissionFlow';
import { GenreSelect } from './pages/GenreSelect';
import { GenrePage } from './pages/GenrePage';
import { SubGenrePage } from './pages/SubGenrePage';
import { GenreIndex } from './pages/GenreIndex';
import { AboutPage } from './pages/AboutPage';
import { ForCuratorsPage } from './pages/ForCuratorsPage';
import { AdminLayout } from './layouts/AdminLayout';
import { AdminDashboard } from './pages/AdminDashboard';
import { AdminPlaylists } from './pages/AdminPlaylists';
import { AdminSubmissions } from './pages/AdminSubmissions';
import { AdminGenres } from './pages/AdminGenres';
import { AdminImport } from './pages/AdminImport';
import { AdminSettings } from './pages/AdminSettings';
import { AdminLogin } from './pages/AdminLogin';

function App() {
  return (
    <ErrorBoundary>
      <div className="min-h-screen flex flex-col">
        <Routes>
          {/* Public routes */}
          <Route path="/" element={<><Header /><HomePage /><Footer /></>} />
          <Route path="/submit" element={<><Header /><SubmissionFlow /><Footer /></>} />
          <Route path="/submit/genre" element={<><Header /><GenreSelect /><Footer /></>} />
          <Route path="/playlist/:slug/*" element={<><Header /><PlaylistPage /><Footer /></>} />
          <Route path="/genres" element={<><Header /><GenreIndex /><Footer /></>} />
          <Route path="/genre/:genre" element={<><Header /><GenrePage /><Footer /></>} />
          <Route path="/genre/:genre/:subGenre" element={<><Header /><SubGenrePage /><Footer /></>} />
          <Route path="/about" element={<><Header /><AboutPage /><Footer /></>} />
          <Route path="/for-curators" element={<><Header /><ForCuratorsPage /><Footer /></>} />
          
          {/* Admin routes */}
          <Route path="/admin/login" element={<AdminLogin />} />
          <Route path="/admin" element={<AdminLayout />}>
            <Route index element={<AdminDashboard />} />
            <Route path="playlists" element={<AdminPlaylists />} />
            <Route path="submissions" element={<AdminSubmissions />} />
            <Route path="genres" element={<AdminGenres />} />
            <Route path="import" element={<AdminImport />} />
            <Route path="settings" element={<AdminSettings />} />
          </Route>

          {/* Fallback route */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </div>
    </ErrorBoundary>
  );
}

export default App;