import React, { useState } from 'react';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import { Plus, Music2, Loader2, AlertCircle } from 'lucide-react';
import { useGenres } from '../hooks/useGenres';
import { Toast } from '../components/Toast';
import { GenreForm } from '../components/GenreForm';
import { SortableGenre } from '../components/SortableGenre';

export function AdminGenres() {
  const { genres, loading, error, deleteGenre, updateGenreOrder } = useGenres();
  const [showGenreForm, setShowGenreForm] = useState(false);
  const [editingGenre, setEditingGenre] = useState<{
    id: string;
    name: string;
    subGenres: { id: string; name: string; }[];
  } | null>(null);
  const [toast, setToast] = useState<{ type: 'success' | 'error', message: string } | null>(null);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = genres.findIndex(g => g.id === active.id);
      const newIndex = genres.findIndex(g => g.id === over.id);

      try {
        await updateGenreOrder(oldIndex, newIndex);
        setToast({
          type: 'success',
          message: 'Genre order updated successfully'
        });
      } catch (err) {
        setToast({
          type: 'error',
          message: 'Failed to update genre order'
        });
      }
    }
  };

  const handleDelete = async (id: string, name: string) => {
    if (!window.confirm(`Are you sure you want to delete "${name}" and all its sub-genres?`)) {
      return;
    }

    try {
      await deleteGenre(id);
      setToast({
        type: 'success',
        message: `${name} has been deleted`
      });
    } catch (err) {
      setToast({
        type: 'error',
        message: err instanceof Error ? err.message : 'Failed to delete genre'
      });
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <Loader2 className="w-8 h-8 text-green-500 animate-spin" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-[400px] text-red-600">
        <AlertCircle className="w-6 h-6 mr-2" />
        <span>Failed to load genres</span>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Genres & Sub-genres</h1>
        <button
          onClick={() => setShowGenreForm(true)}
          className="flex items-center gap-2 bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600"
        >
          <Plus className="w-5 h-5" />
          Add Genre
        </button>
      </div>

      <div className="space-y-4">
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={genres.map(g => g.id)}
            strategy={verticalListSortingStrategy}
          >
            {genres.map((genre) => (
              <SortableGenre
                key={genre.id}
                id={genre.id}
                name={genre.name}
                subGenres={genre.subGenres}
                onEdit={() => setEditingGenre({
                  id: genre.id,
                  name: genre.name,
                  subGenres: genre.subGenres
                })}
                onDelete={() => handleDelete(genre.id, genre.name)}
              />
            ))}
          </SortableContext>
        </DndContext>

        {genres.length === 0 && (
          <div className="text-center py-12 bg-white rounded-lg">
            <Music2 className="w-12 h-12 text-gray-400 mx-auto mb-4" />
            <p className="text-gray-500">No genres added yet</p>
          </div>
        )}
      </div>

      {(showGenreForm || editingGenre) && (
        <GenreForm
          genre={editingGenre}
          onClose={() => {
            setShowGenreForm(false);
            setEditingGenre(null);
          }}
          onSuccess={(genre) => {
            setShowGenreForm(false);
            setEditingGenre(null);
            setToast({
              type: 'success',
              message: `Genre ${editingGenre ? 'updated' : 'created'} successfully`
            });
          }}
        />
      )}

      {toast && (
        <Toast
          message={toast.message}
          type={toast.type}
          onClose={() => setToast(null)}
        />
      )}
    </div>
  );
}