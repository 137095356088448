import React, { useState, useRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Music4, Menu, X } from 'lucide-react';
import { GenreMegaMenu } from './GenreMegaMenu';

export function Header() {
  const location = useLocation();
  const [showMegaMenu, setShowMegaMenu] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout>();
  const menuRef = useRef<HTMLDivElement>(null);
  const isSubmitFlow = location.pathname.startsWith('/submit');

  const handleMouseEnter = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setShowMegaMenu(true);
  };

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setShowMegaMenu(false);
    }, 300);
  };

  // Close mobile menu when route changes
  useEffect(() => {
    setMobileMenuOpen(false);
  }, [location.pathname]);

  // Clean up timeout on unmount
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <header className="bg-gradient-to-r from-green-500 to-green-600 text-white relative z-50">
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center h-16">
          {/* Logo */}
          <Link to="/" className="flex items-center gap-3 hover:opacity-90 transition-opacity">
            <Music4 className="w-8 h-8" />
            <h1 className="text-2xl font-bold whitespace-nowrap">Playlist Partner</h1>
          </Link>

          {/* Submit Track Button (Mobile) */}
          {!isSubmitFlow && (
            <Link
              to="/submit"
              className="md:hidden flex items-center gap-2 bg-white text-green-600 px-4 py-2 rounded-lg hover:bg-green-50 transition-colors"
            >
              Submit
            </Link>
          )}

          {/* Mobile Menu Button */}
          <button
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            className="md:hidden p-2 hover:bg-white/10 rounded-lg ml-2"
            aria-label="Toggle menu"
          >
            {mobileMenuOpen ? (
              <X className="w-6 h-6" />
            ) : (
              <Menu className="w-6 h-6" />
            )}
          </button>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center gap-8">
            <div 
              ref={menuRef}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              className="relative"
            >
              <button className="flex items-center gap-2 text-white hover:text-white/90 transition-colors py-2 px-3 -ml-3">
                Browse Genres
              </button>
              {showMegaMenu && (
                <div className="absolute top-full right-0 w-[800px] bg-white shadow-xl rounded-lg mt-1">
                  <div className="absolute h-3 -top-3 inset-x-0" />
                  <GenreMegaMenu onClose={() => setShowMegaMenu(false)} />
                </div>
              )}
            </div>

            <Link
              to="/for-curators"
              className="flex items-center gap-2 text-white hover:text-white/90 transition-colors py-2 px-3 -ml-3"
            >
              For Curators
            </Link>

            <Link
              to="/about"
              className="flex items-center gap-2 text-white hover:text-white/90 transition-colors py-2 px-3 -ml-3"
            >
              About
            </Link>

            {/* Submit Track Button (Desktop) */}
            {!isSubmitFlow && (
              <Link
                to="/submit"
                className="flex items-center gap-2 bg-white text-green-600 px-4 py-2 rounded-lg hover:bg-green-50 transition-colors"
              >
                Submit Track
              </Link>
            )}
          </div>
        </div>

        {/* Mobile Menu */}
        {mobileMenuOpen && (
          <div className="md:hidden border-t border-white/10">
            <nav className="py-4 space-y-2">
              <Link
                to="/genres"
                className="block px-4 py-2 hover:bg-white/10 rounded-lg"
                onClick={() => setMobileMenuOpen(false)}
              >
                Browse Genres
              </Link>
              <Link
                to="/for-curators"
                className="block px-4 py-2 hover:bg-white/10 rounded-lg"
                onClick={() => setMobileMenuOpen(false)}
              >
                For Curators
              </Link>
              <Link
                to="/about"
                className="block px-4 py-2 hover:bg-white/10 rounded-lg"
                onClick={() => setMobileMenuOpen(false)}
              >
                About
              </Link>
            </nav>
          </div>
        )}
      </div>
    </header>
  );
}