import { useState, useEffect } from 'react';
import { supabase } from '../lib/supabase';

interface PlaylistParams {
  genre?: string;
  subGenre?: string;
  search?: string;
  sortBy?: 'default' | 'followers-high' | 'followers-low';
}

interface PlaylistState {
  playlists: any[];
  currentPage: number;
  filters: PlaylistParams;
}

const STORAGE_KEY = 'playlist_state';

export function usePlaylists(params?: PlaylistParams) {
  const [playlists, setPlaylists] = useState<any[]>(() => {
    try {
      const savedState = sessionStorage.getItem(STORAGE_KEY);
      if (savedState) {
        const state = JSON.parse(savedState) as PlaylistState;
        if (JSON.stringify(state.filters) === JSON.stringify(params)) {
          return state.playlists;
        }
      }
      return [];
    } catch {
      return [];
    }
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [hasMore, setHasMore] = useState(true);
  const [currentPage, setCurrentPage] = useState(() => {
    try {
      const savedState = sessionStorage.getItem(STORAGE_KEY);
      if (savedState) {
        const state = JSON.parse(savedState) as PlaylistState;
        if (JSON.stringify(state.filters) === JSON.stringify(params)) {
          return state.currentPage;
        }
      }
      return 1;
    } catch {
      return 1;
    }
  });
  const [totalCount, setTotalCount] = useState(0);
  const itemsPerPage = 12;

  useEffect(() => {
    const filtersChanged = () => {
      const savedState = sessionStorage.getItem(STORAGE_KEY);
      if (savedState) {
        const state = JSON.parse(savedState) as PlaylistState;
        return JSON.stringify(state.filters) !== JSON.stringify(params);
      }
      return true;
    };

    if (filtersChanged()) {
      setPlaylists([]);
      setCurrentPage(1);
      setHasMore(true);
      sessionStorage.removeItem(STORAGE_KEY);
    }
  }, [params?.genre, params?.subGenre, params?.search, params?.sortBy]);

  useEffect(() => {
    if (playlists.length > 0) {
      const state: PlaylistState = {
        playlists,
        currentPage,
        filters: params || {}
      };
      sessionStorage.setItem(STORAGE_KEY, JSON.stringify(state));
    }
  }, [playlists, currentPage, params]);

  useEffect(() => {
    async function fetchPlaylists() {
      try {
        setLoading(true);
        setError(null);

        let query = supabase
          .from('playlist_details')
          .select('*', { count: 'exact' });

        if (params?.genre) {
          query = query.eq('genre', params.genre);
        }

        if (params?.subGenre) {
          query = query.contains('sub_genres', [params.subGenre]);
        }

        if (params?.search) {
          query = query.or(`name.ilike.%${params.search}%,description.ilike.%${params.search}%`);
        }

        // Add sorting
        switch (params?.sortBy) {
          case 'followers-high':
            query = query.order('followers', { ascending: false });
            break;
          case 'followers-low':
            query = query.order('followers', { ascending: true });
            break;
          default:
            query = query
              .order('featured', { ascending: false })
              .order('created_at', { ascending: false });
        }

        // Add pagination
        const start = (currentPage - 1) * itemsPerPage;
        query = query.range(start, start + itemsPerPage - 1);

        const { data, error: fetchError, count } = await query;

        if (fetchError) throw fetchError;

        setTotalCount(count || 0);
        setPlaylists(prev => {
          const newPlaylists = data || [];
          if (currentPage === 1) return newPlaylists;

          const existingIds = new Set(prev.map(p => p.id));
          const uniqueNewPlaylists = newPlaylists.filter(p => !existingIds.has(p.id));
          
          return [...prev, ...uniqueNewPlaylists];
        });
        setHasMore((start + itemsPerPage) < (count || 0));
      } catch (err) {
        console.error('Failed to fetch playlists:', err);
        setError(err instanceof Error ? err : new Error('Failed to fetch playlists'));
      } finally {
        setLoading(false);
      }
    }

    fetchPlaylists();
  }, [currentPage, params?.genre, params?.subGenre, params?.search, params?.sortBy]);

  const loadMore = () => {
    if (!loading && hasMore) {
      setCurrentPage(prev => prev + 1);
    }
  };

  return {
    playlists,
    loading,
    error,
    hasMore,
    loadMore,
    currentPage,
    totalCount,
    itemsPerPage
  };
}