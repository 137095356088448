import React, { useState, useRef } from 'react';
import { RefreshCw, AlertCircle, CheckCircle, X } from 'lucide-react';
import { supabase } from '../lib/supabase';
import { getPlaylistDetails } from '../utils/spotify';

interface PlaylistSyncProps {
  onClose?: () => void;
  onComplete: () => void;
}

export function PlaylistSync({ onClose, onComplete }: PlaylistSyncProps) {
  const [showModal, setShowModal] = useState(false);
  const [syncing, setSyncing] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [progress, setProgress] = useState<{
    current: number;
    total: number;
    updated: number;
    failed: number;
  } | null>(null);
  const abortController = useRef<AbortController | null>(null);

  const startSync = async () => {
    setSyncing(true);
    setError(null);
    abortController.current = new AbortController();

    try {
      // Fetch all playlists
      const { data: playlists, error: fetchError } = await supabase
        .from('playlists')
        .select('id, spotify_id')
        .order('created_at', { ascending: false });

      if (fetchError) throw fetchError;
      if (!playlists || playlists.length === 0) {
        throw new Error('No playlists found to sync');
      }

      setProgress({
        current: 0,
        total: playlists.length,
        updated: 0,
        failed: 0
      });

      // Process playlists in batches to respect rate limits
      const BATCH_SIZE = 10;
      for (let i = 0; i < playlists.length; i += BATCH_SIZE) {
        // Check if sync was cancelled
        if (abortController.current?.signal.aborted) {
          throw new Error('Sync cancelled');
        }

        const batch = playlists.slice(i, Math.min(i + BATCH_SIZE, playlists.length));
        
        // Process batch concurrently
        const updates = await Promise.allSettled(
          batch.map(async (playlist) => {
            try {
              const spotifyData = await getPlaylistDetails(playlist.spotify_id);
              if (!spotifyData) {
                throw new Error('Playlist not found on Spotify');
              }

              return {
                id: playlist.id,
                name: spotifyData.name,
                description: spotifyData.description || '',
                image_url: spotifyData.images[0]?.url || '',
                followers: spotifyData.followers?.total || 0,
                track_count: spotifyData.tracks?.total || 0,
                curator_name: spotifyData.owner?.display_name || null,
                curator_profile_url: spotifyData.owner?.external_urls?.spotify || null,
                updated_at: new Date().toISOString()
              };
            } catch (error) {
              console.error(`Failed to fetch playlist ${playlist.spotify_id}:`, error);
              throw error;
            }
          })
        );

        // Update database with successful results
        let batchSuccesses = 0;
        let batchFailures = 0;

        for (const result of updates) {
          if (result.status === 'fulfilled') {
            const { error: updateError } = await supabase
              .from('playlists')
              .update(result.value)
              .eq('id', result.value.id);

            if (updateError) {
              console.error('Failed to update playlist:', updateError);
              batchFailures++;
            } else {
              batchSuccesses++;
            }
          } else {
            batchFailures++;
          }
        }

        // Update progress
        setProgress(prev => prev ? {
          ...prev,
          current: Math.min(i + BATCH_SIZE, playlists.length),
          updated: prev.updated + batchSuccesses,
          failed: prev.failed + batchFailures
        } : null);

        // Add delay between batches to respect rate limits
        if (i + BATCH_SIZE < playlists.length) {
          await new Promise(resolve => setTimeout(resolve, 1000));
        }
      }

      onComplete();
      setShowModal(false);
    } catch (err) {
      console.error('Sync failed:', err);
      if (err instanceof Error && err.message === 'Sync cancelled') {
        setError('Sync cancelled');
      } else {
        setError(err instanceof Error ? err.message : 'Failed to sync playlists');
      }
    } finally {
      setSyncing(false);
      abortController.current = null;
    }
  };

  const handleCancel = () => {
    if (syncing) {
      abortController.current?.abort();
    } else {
      setShowModal(false);
      onClose?.();
    }
  };

  return (
    <>
      <div className="bg-white p-6 rounded-lg shadow-sm">
        <div className="flex justify-between items-center mb-4">
          <div>
            <h2 className="text-lg font-semibold">Sync with Spotify</h2>
            <p className="text-sm text-gray-600">
              Update playlist metadata from Spotify including artwork, description, curator details, and follower counts.
            </p>
          </div>
          <button
            onClick={() => setShowModal(true)}
            className="flex items-center gap-2 bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors"
          >
            <RefreshCw className="w-5 h-5" />
            Start Sync
          </button>
        </div>
      </div>

      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-xl max-w-lg w-full">
            <div className="p-6 border-b border-gray-200">
              <div className="flex justify-between items-center">
                <h2 className="text-xl font-bold text-gray-900">Sync Playlists</h2>
                <button
                  onClick={handleCancel}
                  className="text-gray-400 hover:text-gray-500 transition-colors"
                >
                  <X className="w-6 h-6" />
                </button>
              </div>
            </div>

            <div className="p-6 space-y-4">
              {!syncing && !error && (
                <p className="text-gray-600">
                  This will update playlist metadata including artwork, description, curator details, follower count, and track count from Spotify.
                </p>
              )}

              {progress && (
                <div className="space-y-3">
                  <div className="flex items-center justify-between text-sm text-gray-600">
                    <span>Processing playlists...</span>
                    <span>{progress.current} of {progress.total}</span>
                  </div>
                  <div className="w-full h-2 bg-gray-100 rounded-full overflow-hidden">
                    <div
                      className="h-full bg-green-500 transition-all duration-300"
                      style={{ width: `${(progress.current / progress.total) * 100}%` }}
                    />
                  </div>
                  <div className="flex gap-4 text-sm">
                    <span className="flex items-center gap-1 text-green-600">
                      <CheckCircle className="w-4 h-4" />
                      {progress.updated} updated
                    </span>
                    {progress.failed > 0 && (
                      <span className="flex items-center gap-1 text-red-600">
                        <AlertCircle className="w-4 h-4" />
                        {progress.failed} failed
                      </span>
                    )}
                  </div>
                </div>
              )}

              {error && (
                <div className="flex items-center gap-2 text-red-600 bg-red-50 p-3 rounded-lg">
                  <AlertCircle className="w-5 h-5 flex-shrink-0" />
                  <p>{error}</p>
                </div>
              )}

              <div className="flex justify-end gap-3 pt-4">
                <button
                  onClick={handleCancel}
                  className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg transition-colors"
                >
                  {syncing ? 'Cancel' : 'Close'}
                </button>
                {!syncing && (
                  <button
                    onClick={startSync}
                    className="flex items-center gap-2 px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition-colors"
                  >
                    <RefreshCw className="w-5 h-5" />
                    Start Sync
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}