import { useState, useEffect } from 'react';
import { supabase } from '../lib/supabase';

export function usePlaylist(slug: string) {
  const [playlist, setPlaylist] = useState<any | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [relatedPlaylists, setRelatedPlaylists] = useState<any[]>([]);

  useEffect(() => {
    let isMounted = true;

    async function fetchPlaylist() {
      if (!slug) {
        setError(new Error('Invalid playlist URL'));
        setLoading(false);
        return;
      }
      
      try {
        setLoading(true);
        setError(null);

        // Get playlist by slug
        const { data: playlists, error: fetchError } = await supabase
          .from('playlist_details')
          .select('*')
          .eq('slug', slug)
          .limit(1);

        if (fetchError) throw fetchError;
        if (!playlists || playlists.length === 0) {
          throw new Error('Playlist not found');
        }

        const foundPlaylist = playlists[0];

        if (isMounted) {
          setPlaylist(foundPlaylist);

          // Get related playlists
          const { data: related, error: relatedError } = await supabase
            .from('playlist_details')
            .select('*')
            .eq('genre', foundPlaylist.genre)
            .neq('id', foundPlaylist.id)
            .order('followers', { ascending: false })
            .limit(4);

          if (!relatedError && related) {
            setRelatedPlaylists(related);
          }
        }
      } catch (err) {
        console.error('Failed to fetch playlist:', err);
        if (isMounted) {
          setError(err instanceof Error ? err : new Error('Failed to fetch playlist'));
          setPlaylist(null);
          setRelatedPlaylists([]);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    }

    fetchPlaylist();

    return () => {
      isMounted = false;
    };
  }, [slug]);

  return { 
    playlist, 
    loading, 
    error, 
    relatedPlaylists,
    isNotFound: error?.message === 'Playlist not found'
  };
}