interface SubmissionTrack {
  spotifyUrl: string;
  timestamp: number;
}

interface PlaylistSubmission {
  playlistId: string;
  tracks: SubmissionTrack[];
}

const STORAGE_KEY = 'playlist_submissions';

export function getSubmittedPlaylists(spotifyUrl: string): string[] {
  try {
    const submissions: PlaylistSubmission[] = JSON.parse(
      localStorage.getItem(STORAGE_KEY) || '[]'
    );
    
    return submissions
      .filter(sub => sub.tracks.some(track => track.spotifyUrl === spotifyUrl))
      .map(sub => sub.playlistId);
  } catch (error) {
    console.error('Failed to get submitted playlists:', error);
    return [];
  }
}

export function trackSubmission(playlistId: string, spotifyUrl: string): void {
  try {
    const submissions: PlaylistSubmission[] = JSON.parse(
      localStorage.getItem(STORAGE_KEY) || '[]'
    );
    
    const playlistSubmission = submissions.find(sub => sub.playlistId === playlistId);
    if (playlistSubmission) {
      if (!playlistSubmission.tracks.some(track => track.spotifyUrl === spotifyUrl)) {
        playlistSubmission.tracks.push({ spotifyUrl, timestamp: Date.now() });
      }
    } else {
      submissions.push({
        playlistId,
        tracks: [{ spotifyUrl, timestamp: Date.now() }]
      });
    }

    localStorage.setItem(STORAGE_KEY, JSON.stringify(submissions));
  } catch (error) {
    console.error('Failed to track submission:', error);
  }
}

export function hasSubmittedToPlaylist(playlistId: string, spotifyUrl: string): boolean {
  try {
    const submissions: PlaylistSubmission[] = JSON.parse(
      localStorage.getItem(STORAGE_KEY) || '[]'
    );
    
    const playlistSubmission = submissions.find(sub => sub.playlistId === playlistId);
    return !!playlistSubmission?.tracks.some(track => track.spotifyUrl === spotifyUrl);
  } catch (error) {
    console.error('Failed to check submission status:', error);
    return false;
  }
}