export function parseCSV<T>(content: string): T[] {
  // Split content into lines and remove empty lines
  const lines = content.split(/\r?\n/).filter(line => line.trim());
  
  if (lines.length < 2) {
    throw new Error('CSV must contain a header row and at least one data row');
  }

  // Parse header row
  const headers = parseCSVRow(lines[0]);
  const requiredHeaders = [
    'Cover URL',
    'Name',
    'URL',
    'Category',
    'Sub Genres',
    'Description',
    'Curator',
    'Curator URL',
    'Followers',
    'Tracks'
  ];

  // Validate headers
  const missingHeaders = requiredHeaders.filter(
    header => !headers.includes(header)
  );

  if (missingHeaders.length > 0) {
    throw new Error(`Missing required columns: ${missingHeaders.join(', ')}`);
  }

  // Parse data rows
  const data: T[] = [];
  for (let i = 1; i < lines.length; i++) {
    try {
      const values = parseCSVRow(lines[i]);
      if (values.length !== headers.length) {
        console.warn(`Skipping row ${i + 1}: column count mismatch`);
        continue;
      }

      // Create object from headers and values
      const row = headers.reduce((obj, header, index) => {
        const value = values[index]?.trim() || '';
        obj[header as keyof T] = value as T[keyof T];
        return obj;
      }, {} as T);

      // Validate required fields
      const hasEmptyRequired = requiredHeaders.some(header => 
        !row[header as keyof T]
      );

      if (hasEmptyRequired) {
        console.warn(`Skipping row ${i + 1}: missing required values`);
        continue;
      }

      data.push(row);
    } catch (error) {
      console.warn(`Skipping row ${i + 1} due to parsing error:`, error);
    }
  }

  if (data.length === 0) {
    throw new Error('No valid data rows found in CSV');
  }

  return data;
}

function parseCSVRow(row: string): string[] {
  const values: string[] = [];
  let currentValue = '';
  let insideQuotes = false;
  let i = 0;

  while (i < row.length) {
    const char = row[i];

    if (char === '"') {
      if (insideQuotes && row[i + 1] === '"') {
        // Handle escaped quotes
        currentValue += '"';
        i += 2;
      } else {
        // Toggle quote state
        insideQuotes = !insideQuotes;
        i++;
      }
    } else if (char === ',' && !insideQuotes) {
      // End of field
      values.push(currentValue.trim());
      currentValue = '';
      i++;
    } else {
      currentValue += char;
      i++;
    }
  }

  // Add the last value
  values.push(currentValue.trim());
  return values;
}