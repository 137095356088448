import { supabase } from '../lib/supabase';
import type { Database } from './database.types';

interface ImportProgress {
  current: number;
  total: number;
  processed: number;
  skipped: number;
  failed: number;
  unmatchedCategories: Set<string>;
  recentErrors: { row: number; message: string }[];
}

type ProgressCallback = (progress: ImportProgress) => void;

export const playlistsService = {
  async importPlaylists(playlists: any[], onProgress?: ProgressCallback) {
    try {
      // Validate input
      if (!Array.isArray(playlists) || playlists.length === 0) {
        throw new Error('No playlists provided');
      }

      // Initialize progress tracking
      const progress: ImportProgress = {
        current: 0,
        total: playlists.length,
        processed: 0,
        skipped: 0,
        failed: 0,
        unmatchedCategories: new Set<string>(),
        recentErrors: []
      };

      // Fetch available genres and sub-genres
      const { data: genres, error: genresError } = await supabase
        .from('genres')
        .select('*')
        .order('name');

      if (genresError) throw genresError;
      if (!genres?.length) {
        throw new Error('No genres found. Please set up genres first.');
      }

      const { data: subGenres, error: subGenresError } = await supabase
        .from('sub_genres')
        .select('*')
        .order('name');

      if (subGenresError) throw subGenresError;

      // Process playlists in batches
      const BATCH_SIZE = 50;
      for (let i = 0; i < playlists.length; i += BATCH_SIZE) {
        const batch = playlists.slice(i, Math.min(i + BATCH_SIZE, playlists.length));
        const playlistData = [];

        // Process each playlist in batch
        for (const playlist of batch) {
          try {
            // Find matching genre
            const genre = genres.find(g => 
              g.name.toLowerCase() === playlist.Category.toLowerCase() ||
              playlist.Category.toLowerCase().includes(g.name.toLowerCase())
            );

            if (!genre) {
              progress.unmatchedCategories.add(playlist.Category);
              progress.failed++;
              progress.recentErrors.push({
                row: i + batch.indexOf(playlist) + 1,
                message: `No matching genre found for: ${playlist.Category}`
              });
              continue;
            }

            // Find matching sub-genres
            const subGenreNames = playlist['Sub Genres']
              .split(';')
              .map((sg: string) => sg.trim())
              .filter(Boolean);

            const subGenreIds = subGenres
              ?.filter(sg => 
                sg.genre_id === genre.id && 
                subGenreNames.some(name => 
                  name.toLowerCase().includes(sg.name.toLowerCase()) ||
                  sg.name.toLowerCase().includes(name.toLowerCase())
                )
              )
              .map(sg => sg.id) || [];

            // Check if playlist already exists
            const { data: existing } = await supabase
              .from('playlists')
              .select('id')
              .eq('spotify_id', playlist.URL.split('/').pop())
              .single();

            if (existing) {
              progress.skipped++;
              continue;
            }

            // Prepare playlist data
            playlistData.push({
              spotify_id: playlist.URL.split('/').pop(),
              name: playlist.Name,
              description: playlist.Description,
              image_url: playlist['Cover URL'],
              followers: parseInt(playlist.Followers.replace(/,/g, ''), 10),
              track_count: parseInt(playlist.Tracks.replace(/,/g, ''), 10),
              genre_id: genre.id,
              sub_genre_ids: subGenreIds,
              curator_name: playlist.Curator,
              curator_profile_url: playlist['Curator URL'],
              created_at: new Date().toISOString(),
              updated_at: new Date().toISOString()
            });

            progress.processed++;
          } catch (error) {
            progress.failed++;
            progress.recentErrors.push({
              row: i + batch.indexOf(playlist) + 1,
              message: error instanceof Error ? error.message : 'Failed to process playlist'
            });
          }
        }

        // Insert valid playlists
        if (playlistData.length > 0) {
          const { error: insertError } = await supabase
            .from('playlists')
            .insert(playlistData);

          if (insertError) {
            console.error('Failed to insert batch:', insertError);
            progress.failed += playlistData.length;
            progress.processed -= playlistData.length;
          }
        }

        // Update progress
        progress.current = i + batch.length;
        if (onProgress) {
          onProgress({ ...progress });
        }

        // Add delay between batches
        if (i + BATCH_SIZE < playlists.length) {
          await new Promise(resolve => setTimeout(resolve, 1000));
        }
      }

      return {
        successCount: progress.processed,
        skipCount: progress.skipped,
        unmatchedCategories: Array.from(progress.unmatchedCategories),
        errors: progress.recentErrors
      };
    } catch (error) {
      console.error('Import failed:', error);
      throw error;
    }
  }
};