import React from 'react';
import { DashboardStats } from '../components/DashboardStats';

const MOCK_STATS = {
  totalPlaylists: 156,
  activeSubmissions: 423,
  totalUsers: 1205,
  submissionRate: 8.4
};

export function AdminDashboard() {
  return (
    <div>
      <h1 className="text-2xl font-bold text-gray-900 mb-8">Dashboard</h1>

      <div className="mb-8">
        <DashboardStats stats={MOCK_STATS} />
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div className="bg-white rounded-lg shadow-sm p-6">
          <h2 className="text-lg font-semibold text-gray-900 mb-4">Recent Activity</h2>
          <div className="space-y-4">
            <p className="text-gray-600">Recent activity feed coming soon...</p>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-sm p-6">
          <h2 className="text-lg font-semibold text-gray-900 mb-4">Top Playlists</h2>
          <div className="space-y-4">
            <p className="text-gray-600">Top playlists data coming soon...</p>
          </div>
        </div>
      </div>
    </div>
  );
}