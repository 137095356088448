import React, { useState } from 'react';
import { Music4, AlertCircle, Loader2, CheckCircle, Disc, Users } from 'lucide-react';
import { supabase } from '../lib/supabase';
import { SEO } from '../components/SEO';
import { extractSpotifyId } from '../utils/spotify';

export function ForCuratorsPage() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    playlistUrl: ''
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);

  const validatePlaylistUrl = (url: string) => {
    const playlistId = extractSpotifyId(url, 'playlist');
    if (!playlistId) {
      throw new Error('Please enter a valid Spotify playlist URL');
    }
    return playlistId;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    try {
      // Validate email
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
        throw new Error('Please enter a valid email address');
      }

      // Validate playlist URL
      const playlistId = validatePlaylistUrl(formData.playlistUrl);

      // Submit application
      const { error: submitError } = await supabase
        .from('curator_applications')
        .insert({
          name: formData.name,
          email: formData.email,
          playlist_url: formData.playlistUrl,
          playlist_id: playlistId
        });

      if (submitError) throw submitError;

      setSuccess(true);
      setFormData({ name: '', email: '', playlistUrl: '' });
    } catch (err) {
      console.error('Application submission failed:', err);
      setError(err instanceof Error ? err.message : 'Failed to submit application');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <SEO 
        title="Become a Curator - PlaylistPartner"
        description="Join our network of Spotify playlist curators. Apply to list your playlists and connect with artists in your genre."
      />

      <div className="bg-gradient-to-b from-green-50 to-white">
        <div className="container mx-auto px-4 py-16 md:py-24">
          <div className="max-w-4xl mx-auto">
            {/* Header */}
            <div className="text-center mb-16">
              <Music4 className="w-12 h-12 text-green-600 mx-auto mb-6" />
              <h1 className="text-4xl font-bold text-gray-900 mb-12">
                Join Our Curator Network
              </h1>

              <div className="grid md:grid-cols-2 gap-8">
                <div className="bg-white p-8 rounded-xl shadow-sm">
                  <div className="p-3 bg-green-50 rounded-lg w-12 h-12 flex items-center justify-center mx-auto mb-4">
                    <Disc className="w-6 h-6 text-green-600" />
                  </div>
                  <h2 className="text-xl font-bold mb-4">For Music Curators</h2>
                  <p className="text-gray-600">
                    Love discovering and sharing new music? Add your playlists to PlaylistPartner to receive fresh submissions 
                    from independent artists, grow your following, and strengthen your reputation as a tastemaker in your genre.
                  </p>
                </div>

                <div className="bg-white p-8 rounded-xl shadow-sm">
                  <div className="p-3 bg-green-50 rounded-lg w-12 h-12 flex items-center justify-center mx-auto mb-4">
                    <Users className="w-6 h-6 text-green-600" />
                  </div>
                  <h2 className="text-xl font-bold mb-4">For Artists</h2>
                  <p className="text-gray-600">
                    Already making music? List your artist playlists to connect with other independent artists in your genre, 
                    expand your network, and build meaningful relationships within your music community.
                  </p>
                </div>
              </div>
            </div>

            {/* What We Look For */}
            <div className="bg-white p-8 rounded-xl shadow-sm mb-12">
              <h2 className="text-2xl font-bold mb-6">What We Look For</h2>
              <p className="text-gray-600 mb-4">
                Our curator network maintains high standards to ensure quality. We look for:
              </p>
              <ul className="space-y-3 text-gray-600">
                <li className="flex items-center gap-2">
                  <CheckCircle className="w-5 h-5 text-green-500 flex-shrink-0" />
                  Consistent playlist activity with regular updates
                </li>
                <li className="flex items-center gap-2">
                  <CheckCircle className="w-5 h-5 text-green-500 flex-shrink-0" />
                  Genuine follower engagement
                </li>
                <li className="flex items-center gap-2">
                  <CheckCircle className="w-5 h-5 text-green-500 flex-shrink-0" />
                  Clear genre focus and curation style
                </li>
                <li className="flex items-center gap-2">
                  <CheckCircle className="w-5 h-5 text-green-500 flex-shrink-0" />
                  Professional playlist presentation
                </li>
              </ul>
            </div>

            {/* Application Form */}
            <div className="bg-white p-8 rounded-xl shadow-sm">
              {success ? (
                <div className="text-center py-8">
                  <CheckCircle className="w-16 h-16 text-green-500 mx-auto mb-4" />
                  <h2 className="text-2xl font-bold text-gray-900 mb-4">Application Received!</h2>
                  <p className="text-gray-600 mb-6">
                    Thank you for your interest in joining PlaylistPartner. We'll review your application 
                    and get back to you as soon as possible.
                  </p>
                  <button
                    onClick={() => setSuccess(false)}
                    className="text-green-600 hover:text-green-700 font-medium"
                  >
                    Submit Another Application
                  </button>
                </div>
              ) : (
                <>
                  <h2 className="text-2xl font-bold mb-6">Apply to Join</h2>
                  <form onSubmit={handleSubmit} className="space-y-6">
                    <div>
                      <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
                        Your Name
                      </label>
                      <input
                        type="text"
                        id="name"
                        value={formData.name}
                        onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                        className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent"
                        placeholder="Enter your name"
                        required
                        disabled={loading}
                      />
                    </div>

                    <div>
                      <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                        Email Address
                      </label>
                      <input
                        type="email"
                        id="email"
                        value={formData.email}
                        onChange={(e) => setFormData(prev => ({ ...prev, email: e.target.value }))}
                        className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent"
                        placeholder="you@example.com"
                        required
                        disabled={loading}
                      />
                    </div>

                    <div>
                      <label htmlFor="playlistUrl" className="block text-sm font-medium text-gray-700 mb-1">
                        Your Best Playlist
                      </label>
                      <input
                        type="url"
                        id="playlistUrl"
                        value={formData.playlistUrl}
                        onChange={(e) => setFormData(prev => ({ ...prev, playlistUrl: e.target.value }))}
                        className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent"
                        placeholder="https://open.spotify.com/playlist/..."
                        required
                        disabled={loading}
                      />
                      <p className="mt-1 text-sm text-gray-500">
                        Please provide the Spotify URL of your best performing playlist
                      </p>
                    </div>

                    {error && (
                      <div className="flex items-center gap-2 text-red-600 bg-red-50 p-3 rounded-lg">
                        <AlertCircle className="w-5 h-5 flex-shrink-0" />
                        <p>{error}</p>
                      </div>
                    )}

                    <button
                      type="submit"
                      disabled={loading}
                      className="w-full flex items-center justify-center gap-2 bg-green-500 text-white p-3 rounded-lg hover:bg-green-600 transition-colors disabled:bg-gray-400 disabled:cursor-not-allowed font-medium"
                    >
                      {loading ? (
                        <>
                          <Loader2 className="w-5 h-5 animate-spin" />
                          Submitting...
                        </>
                      ) : (
                        'Submit Application'
                      )}
                    </button>
                  </form>
                </>
              )}
            </div>

            {/* What Happens Next */}
            <div className="mt-12 bg-white p-8 rounded-xl shadow-sm">
              <h2 className="text-2xl font-bold mb-6">What Happens Next?</h2>
              <div className="space-y-6">
                <div className="flex items-start gap-4">
                  <div className="w-8 h-8 bg-green-100 rounded-full flex items-center justify-center flex-shrink-0">
                    <span className="text-green-600 font-bold">1</span>
                  </div>
                  <div>
                    <p className="text-gray-600">Submit your application with links to your playlists</p>
                  </div>
                </div>

                <div className="flex items-start gap-4">
                  <div className="w-8 h-8 bg-green-100 rounded-full flex items-center justify-center flex-shrink-0">
                    <span className="text-green-600 font-bold">2</span>
                  </div>
                  <div>
                    <p className="text-gray-600">Our team reviews your submission against our quality criteria</p>
                  </div>
                </div>

                <div className="flex items-start gap-4">
                  <div className="w-8 h-8 bg-green-100 rounded-full flex items-center justify-center flex-shrink-0">
                    <span className="text-green-600 font-bold">3</span>
                  </div>
                  <div>
                    <p className="text-gray-600">If approved, you'll receive instructions for adding your playlists</p>
                  </div>
                </div>

                <div className="flex items-start gap-4">
                  <div className="w-8 h-8 bg-green-100 rounded-full flex items-center justify-center flex-shrink-0">
                    <span className="text-green-600 font-bold">4</span>
                  </div>
                  <div>
                    <p className="text-gray-600">Start connecting with artists and growing your network</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}