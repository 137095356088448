import React from 'react';
import { Link } from 'react-router-dom';
import { Music4 } from 'lucide-react';
import { useGenres } from '../hooks/useGenres';

export function Footer() {
  const { genres } = useGenres();

  return (
    <footer className="bg-gray-900 text-gray-300 mt-auto">
      <div className="container mx-auto px-4 py-12">
        {/* Logo and Description */}
        <div className="mb-12">
          <div className="flex items-center gap-3 mb-4">
            <Music4 className="w-8 h-8" />
            <h2 className="text-2xl font-bold">Playlist Partner</h2>
          </div>
          <p className="text-gray-400 max-w-2xl">
            Submit your music to thousands of independent Spotify playlist curators for free. 
            No signup required—connect with curators and grow your audience today.
          </p>
        </div>

        {/* Genre Navigation */}
        <div className="border-t border-gray-800 pt-8">
          <h3 className="text-lg font-semibold mb-6">Browse Genres</h3>
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-x-4 gap-y-3">
            {genres.map((genre) => (
              <Link 
                key={genre.id}
                to={`/genre/${genre.slug}`}
                className="text-gray-400 hover:text-green-500 transition-colors"
              >
                {genre.name}
              </Link>
            ))}
          </div>
        </div>

        {/* Copyright */}
        <div className="border-t border-gray-800 mt-12 pt-8 text-center text-sm text-gray-500">
          <p>© {new Date().getFullYear()} PlaylistPartner. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}