import { useState, useEffect } from 'react';
import { submissionsService } from '../services/submissions.service';
import type { Database } from '../services/database.types';

type Submission = Database['public']['Tables']['submissions']['Row'] & {
  playlist?: {
    name: string;
  };
};

export function useSubmissions(playlistId?: string) {
  const [submissions, setSubmissions] = useState<Submission[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    async function fetchSubmissions() {
      try {
        setLoading(true);
        setError(null);
        
        // If playlistId is provided, fetch for specific playlist
        // Otherwise fetch all submissions (admin view)
        const data = playlistId 
          ? await submissionsService.getForPlaylist(playlistId)
          : await submissionsService.getAll();
          
        setSubmissions(data);
      } catch (err) {
        console.error('Failed to fetch submissions:', err);
        setError(err instanceof Error ? err : new Error('Failed to fetch submissions'));
      } finally {
        setLoading(false);
      }
    }

    fetchSubmissions();
  }, [playlistId]);

  const updateStatus = async (id: string, status: 'approved' | 'rejected' | 'pending') => {
    try {
      const updated = await submissionsService.updateStatus(id, status);
      setSubmissions(prev => 
        prev.map(sub => sub.id === id ? { ...sub, ...updated } : sub)
      );
      return updated;
    } catch (error) {
      console.error('Failed to update submission status:', error);
      throw error;
    }
  };

  return {
    submissions,
    loading,
    error,
    updateStatus
  };
}