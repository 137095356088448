import { createPlaylistSlug } from './formatting';

interface ScrollPosition {
  pathname: string;
  position: number;
  timestamp: number;
  searchParams?: string;
}

const STORAGE_KEY = 'scrollPositions';
const EXPIRY_TIME = 5 * 60 * 1000; // 5 minutes
const MAX_RESTORE_ATTEMPTS = 10;
const RESTORE_DELAY = 100;

function getPositions(): ScrollPosition[] {
  try {
    return JSON.parse(sessionStorage.getItem(STORAGE_KEY) || '[]');
  } catch {
    return [];
  }
}

export const scrollManager = {
  savePosition(pathname: string, searchParams?: URLSearchParams) {
    try {
      const positions = getPositions();
      const currentPosition = window.scrollY;
      
      if (currentPosition > 0) {
        positions.push({
          pathname,
          position: currentPosition,
          timestamp: Date.now(),
          searchParams: searchParams?.toString()
        });
        
        // Clean up old positions
        const validPositions = positions.filter(p => 
          Date.now() - p.timestamp < EXPIRY_TIME
        );
        
        sessionStorage.setItem(STORAGE_KEY, JSON.stringify(validPositions));
      }
    } catch (error) {
      console.error('Failed to save scroll position:', error);
    }
  },

  restorePosition(pathname: string, setSearchParams?: (params: URLSearchParams) => void) {
    try {
      const positions = getPositions();
      const savedPosition = positions.find(p => p.pathname === pathname);
      
      if (savedPosition) {
        // Remove the used position
        const updatedPositions = positions.filter(p => p !== savedPosition);
        sessionStorage.setItem(STORAGE_KEY, JSON.stringify(updatedPositions));
        
        // Restore search params if they exist
        if (savedPosition.searchParams && setSearchParams) {
          setSearchParams(new URLSearchParams(savedPosition.searchParams));
        }
        
        let attempts = 0;

        const attemptScroll = () => {
          const currentHeight = document.documentElement.scrollHeight;
          const targetPosition = savedPosition.position;

          // If we can scroll to the position or we've hit max attempts, do the scroll
          if (currentHeight >= targetPosition || attempts >= MAX_RESTORE_ATTEMPTS) {
            window.scrollTo({ top: targetPosition, behavior: 'instant' });
            return;
          }

          // Try again after a delay
          attempts++;
          setTimeout(attemptScroll, RESTORE_DELAY);
        };

        // Start the first attempt after a short delay to allow for initial render
        setTimeout(attemptScroll, 50);
      }
    } catch (error) {
      console.error('Failed to restore scroll position:', error);
    }
  }
};