import React, { useState, useCallback } from 'react';
import { Upload, X, AlertCircle, Loader2 } from 'lucide-react';
import { supabase } from '../lib/supabase';
import { slugify } from '../utils/formatting';

interface GenreRow {
  Name: string;
  Slug: string;
  Description: string;
}

interface SubGenreRow {
  Name: string;
  Slug: string;
  Description: string;
}

interface GenreImportProps {
  onSuccess: () => void;
}

export function GenreImport({ onSuccess }: GenreImportProps) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [genresFile, setGenresFile] = useState<File | null>(null);
  const [subGenresFile, setSubGenresFile] = useState<File | null>(null);
  const [progress, setProgress] = useState<{
    stage: string;
    current: number;
    total: number;
  } | null>(null);

  const processFiles = async () => {
    if (!genresFile || !subGenresFile) {
      setError('Please select both genre and sub-genre files');
      return;
    }

    setLoading(true);
    setError(null);
    setProgress({ stage: 'Reading files', current: 0, total: 2 });

    try {
      // Read and parse genres CSV
      const genresText = await genresFile.text();
      const genreRows = genresText.split('\n');
      const genreHeaders = genreRows[0].split(',').map(h => h.trim());
      
      // Validate genre headers
      const requiredGenreColumns = ['Name', 'Slug', 'Description'];
      const missingGenreColumns = requiredGenreColumns.filter(col => !genreHeaders.includes(col));
      if (missingGenreColumns.length > 0) {
        throw new Error(`Missing required columns in genres file: ${missingGenreColumns.join(', ')}`);
      }

      // Parse genres
      const genres: GenreRow[] = [];
      for (let i = 1; i < genreRows.length; i++) {
        if (!genreRows[i].trim()) continue;
        const values = genreRows[i].split(',').map(v => v.trim());
        genres.push({
          Name: values[genreHeaders.indexOf('Name')],
          Slug: values[genreHeaders.indexOf('Slug')],
          Description: values[genreHeaders.indexOf('Description')]
        });
      }

      setProgress({ stage: 'Processing genres', current: 1, total: 2 });

      // Read and parse sub-genres CSV
      const subGenresText = await subGenresFile.text();
      const subGenreRows = subGenresText.split('\n');
      const subGenreHeaders = subGenreRows[0].split(',').map(h => h.trim());
      
      // Validate sub-genre headers
      const requiredSubGenreColumns = ['Name', 'Slug', 'Description'];
      const missingSubGenreColumns = requiredSubGenreColumns.filter(col => !subGenreHeaders.includes(col));
      if (missingSubGenreColumns.length > 0) {
        throw new Error(`Missing required columns in sub-genres file: ${missingSubGenreColumns.join(', ')}`);
      }

      // Parse sub-genres
      const subGenres: SubGenreRow[] = [];
      for (let i = 1; i < subGenreRows.length; i++) {
        if (!subGenreRows[i].trim()) continue;
        const values = subGenreRows[i].split(',').map(v => v.trim());
        subGenres.push({
          Name: values[subGenreHeaders.indexOf('Name')],
          Slug: values[subGenreHeaders.indexOf('Slug')],
          Description: values[subGenreHeaders.indexOf('Description')]
        });
      }

      setProgress({ stage: 'Importing data', current: 0, total: genres.length + subGenres.length });

      // Import genres
      for (const genre of genres) {
        const { error: insertError } = await supabase
          .from('genres')
          .insert({
            name: genre.Name,
            slug: genre.Slug || slugify(genre.Name),
            description: genre.Description
          });

        if (insertError && insertError.code !== '23505') { // Ignore unique constraint violations
          throw insertError;
        }

        setProgress(prev => prev ? {
          ...prev,
          current: prev.current + 1
        } : null);
      }

      // Get inserted genres for mapping
      const { data: insertedGenres, error: genresError } = await supabase
        .from('genres')
        .select('id, name, slug');

      if (genresError) throw genresError;

      // Create a map for quick genre lookup
      const genreMap = new Map(
        insertedGenres.map(g => [g.name.toLowerCase(), g.id])
      );

      // Import sub-genres
      for (const subGenre of subGenres) {
        // Try to find matching genre
        const matchingGenre = insertedGenres.find(g => 
          subGenre.Slug.includes(g.slug) || 
          subGenre.Name.toLowerCase().includes(g.name.toLowerCase())
        );

        if (matchingGenre) {
          const { error: insertError } = await supabase
            .from('sub_genres')
            .insert({
              name: subGenre.Name,
              slug: subGenre.Slug || slugify(subGenre.Name),
              description: subGenre.Description,
              genre_id: matchingGenre.id
            });

          if (insertError && insertError.code !== '23505') { // Ignore unique constraint violations
            throw insertError;
          }
        }

        setProgress(prev => prev ? {
          ...prev,
          current: prev.current + 1
        } : null);
      }

      onSuccess();
    } catch (err) {
      console.error('Import failed:', err);
      setError(err instanceof Error ? err.message : 'Failed to import data');
    } finally {
      setLoading(false);
      setProgress(null);
      setGenresFile(null);
      setSubGenresFile(null);
    }
  };

  const handleGenresFile = (file: File) => {
    if (!file.name.endsWith('.csv')) {
      setError('Please select a CSV file');
      return;
    }
    setGenresFile(file);
    setError(null);
  };

  const handleSubGenresFile = (file: File) => {
    if (!file.name.endsWith('.csv')) {
      setError('Please select a CSV file');
      return;
    }
    setSubGenresFile(file);
    setError(null);
  };

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-2 gap-6">
        <div className="bg-white p-6 rounded-lg shadow-sm">
          <h3 className="text-lg font-medium mb-4">Import Genres</h3>
          <div
            onDragOver={(e) => e.preventDefault()}
            onDrop={(e) => {
              e.preventDefault();
              const file = e.dataTransfer.files[0];
              if (file) handleGenresFile(file);
            }}
            className={`
              border-2 border-dashed rounded-lg p-6 text-center transition-colors
              ${loading ? 'opacity-50 cursor-not-allowed' : 'hover:border-green-500 cursor-pointer'}
              ${genresFile ? 'border-green-500 bg-green-50' : 'border-gray-300'}
            `}
          >
            {genresFile ? (
              <div className="flex items-center justify-between">
                <span className="text-sm text-gray-600">{genresFile.name}</span>
                <button
                  onClick={() => setGenresFile(null)}
                  className="p-1 hover:bg-green-100 rounded-full"
                  disabled={loading}
                >
                  <X className="w-4 h-4 text-gray-500" />
                </button>
              </div>
            ) : (
              <>
                <Upload className="w-8 h-8 text-gray-400 mx-auto mb-2" />
                <p className="text-sm text-gray-600 mb-2">
                  Drop genres CSV file here
                </p>
                <input
                  type="file"
                  accept=".csv"
                  onChange={(e) => {
                    const file = e.target.files?.[0];
                    if (file) handleGenresFile(file);
                  }}
                  className="hidden"
                  id="genres-file"
                  disabled={loading}
                />
                <label
                  htmlFor="genres-file"
                  className="inline-block px-3 py-1 text-sm bg-green-500 text-white rounded hover:bg-green-600 transition-colors cursor-pointer"
                >
                  Select File
                </label>
              </>
            )}
          </div>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-sm">
          <h3 className="text-lg font-medium mb-4">Import Sub-genres</h3>
          <div
            onDragOver={(e) => e.preventDefault()}
            onDrop={(e) => {
              e.preventDefault();
              const file = e.dataTransfer.files[0];
              if (file) handleSubGenresFile(file);
            }}
            className={`
              border-2 border-dashed rounded-lg p-6 text-center transition-colors
              ${loading ? 'opacity-50 cursor-not-allowed' : 'hover:border-green-500 cursor-pointer'}
              ${subGenresFile ? 'border-green-500 bg-green-50' : 'border-gray-300'}
            `}
          >
            {subGenresFile ? (
              <div className="flex items-center justify-between">
                <span className="text-sm text-gray-600">{subGenresFile.name}</span>
                <button
                  onClick={() => setSubGenresFile(null)}
                  className="p-1 hover:bg-green-100 rounded-full"
                  disabled={loading}
                >
                  <X className="w-4 h-4 text-gray-500" />
                </button>
              </div>
            ) : (
              <>
                <Upload className="w-8 h-8 text-gray-400 mx-auto mb-2" />
                <p className="text-sm text-gray-600 mb-2">
                  Drop sub-genres CSV file here
                </p>
                <input
                  type="file"
                  accept=".csv"
                  onChange={(e) => {
                    const file = e.target.files?.[0];
                    if (file) handleSubGenresFile(file);
                  }}
                  className="hidden"
                  id="sub-genres-file"
                  disabled={loading}
                />
                <label
                  htmlFor="sub-genres-file"
                  className="inline-block px-3 py-1 text-sm bg-green-500 text-white rounded hover:bg-green-600 transition-colors cursor-pointer"
                >
                  Select File
                </label>
              </>
            )}
          </div>
        </div>
      </div>

      {error && (
        <div className="p-3 bg-red-50 text-red-700 rounded-lg flex items-center gap-2">
          <AlertCircle className="w-5 h-5 flex-shrink-0" />
          <p className="flex-1">{error}</p>
          <button onClick={() => setError(null)}>
            <X className="w-5 h-5" />
          </button>
        </div>
      )}

      {progress && (
        <div className="bg-white p-4 rounded-lg shadow-sm">
          <div className="flex items-center gap-3 mb-2">
            <Loader2 className="w-5 h-5 text-green-500 animate-spin" />
            <span className="text-gray-600">{progress.stage}...</span>
          </div>
          <div className="w-full h-2 bg-gray-100 rounded-full">
            <div
              className="h-full bg-green-500 rounded-full transition-all"
              style={{
                width: `${(progress.current / progress.total) * 100}%`
              }}
            />
          </div>
        </div>
      )}

      <button
        onClick={processFiles}
        disabled={!genresFile || !subGenresFile || loading}
        className={`w-full py-2 rounded-lg text-white transition-colors ${
          !genresFile || !subGenresFile || loading
            ? 'bg-gray-300 cursor-not-allowed'
            : 'bg-green-500 hover:bg-green-600'
        }`}
      >
        {loading ? 'Importing...' : 'Import Data'}
      </button>

      <div className="text-sm text-gray-500">
        <p className="font-medium mb-2">CSV Format Requirements:</p>
        <ul className="list-disc list-inside space-y-1">
          <li>Files must be in CSV format</li>
          <li>First row must contain headers</li>
          <li>Required columns: Name, Slug, Description</li>
          <li>Values containing commas must be enclosed in quotes</li>
        </ul>
      </div>
    </div>
  );
}