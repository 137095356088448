import React from 'react';
import { Search, Music2 } from 'lucide-react';
import { useGenres } from '../hooks/useGenres';

interface FilterState {
  search: string;
  genre?: string;
  subGenre?: string;
  sortBy: 'default' | 'followers-high' | 'followers-low';
}

interface FilterBarProps {
  filters: FilterState;
  onFilterChange: (filters: FilterState) => void;
  hideGenreFilter?: boolean;
}

export function FilterBar({ filters, onFilterChange, hideGenreFilter }: FilterBarProps) {
  const { genres, loading } = useGenres();
  const selectedGenre = genres.find(g => g.name === filters.genre);

  return (
    <div className="bg-white shadow-sm -mx-4 px-4">
      <div className="max-w-[1400px] mx-auto">
        <div className={`grid ${hideGenreFilter ? 'grid-cols-2' : 'grid-cols-1 md:grid-cols-2 lg:grid-cols-4'} gap-4 py-4`}>
          <div className="relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
            <input
              type="text"
              placeholder="Search playlists..."
              value={filters.search}
              onChange={(e) => onFilterChange({ ...filters, search: e.target.value })}
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent"
            />
          </div>

          {!hideGenreFilter && (
            <div className="relative">
              <Music2 className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
              <select
                value={filters.genre}
                onChange={(e) => onFilterChange({ 
                  ...filters, 
                  genre: e.target.value,
                  subGenre: '' // Reset sub-genre when genre changes
                })}
                className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg appearance-none focus:ring-2 focus:ring-green-500 focus:border-transparent disabled:bg-gray-50 disabled:text-gray-500"
                disabled={loading}
              >
                <option value="">All Genres</option>
                {genres.map((genre) => (
                  <option key={genre.id} value={genre.name}>
                    {genre.name}
                  </option>
                ))}
              </select>
            </div>
          )}

          <div className="relative">
            <select
              value={filters.subGenre || ''}
              onChange={(e) => onFilterChange({ ...filters, subGenre: e.target.value })}
              className={`w-full px-4 py-2 border border-gray-300 rounded-lg appearance-none transition-colors
                ${!selectedGenre ? 'bg-gray-50 text-gray-400 cursor-not-allowed' : 'focus:ring-2 focus:ring-green-500 focus:border-transparent'}`}
              disabled={!selectedGenre}
            >
              <option value="">
                {!selectedGenre ? '-- Select a genre first --' : 'All Sub-genres'}
              </option>
              {selectedGenre?.subGenres.map((subGenre) => (
                <option key={subGenre.id} value={subGenre.name}>
                  {subGenre.name}
                </option>
              ))}
            </select>
          </div>

          <div className="relative">
            <select
              value={filters.sortBy}
              onChange={(e) => onFilterChange({ 
                ...filters, 
                sortBy: e.target.value as FilterState['sortBy']
              })}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg appearance-none focus:ring-2 focus:ring-green-500 focus:border-transparent"
            >
              <option value="default">Default (Most Recent)</option>
              <option value="followers-high">Most Followers</option>
              <option value="followers-low">Least Followers</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
}