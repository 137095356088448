interface SavedSubmissionData {
  artistName: string;
  email: string;
  lastTrack: SpotifyTrack | null;
  lastUpdated: number;
}

const STORAGE_KEY = 'last_submission_data';
const EXPIRY_TIME = 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds

export function saveSubmissionData(data: { 
  artistName: string; 
  email: string;
  track: SpotifyTrack | null;
}): void {
  try {
    const saveData: SavedSubmissionData = {
      artistName: data.artistName,
      email: data.email,
      lastTrack: data.track,
      lastUpdated: Date.now(),
    };
    localStorage.setItem(STORAGE_KEY, JSON.stringify(saveData));
  } catch (error) {
    console.error('Failed to save submission data:', error);
  }
}

export function getLastSubmissionData(): { 
  artistName: string; 
  email: string;
  lastTrack: SpotifyTrack | null;
} | null {
  try {
    const savedData = localStorage.getItem(STORAGE_KEY);
    if (!savedData) return null;

    const data: SavedSubmissionData = JSON.parse(savedData);
    
    // Check if data is expired
    if (Date.now() - data.lastUpdated > EXPIRY_TIME) {
      localStorage.removeItem(STORAGE_KEY);
      return null;
    }

    return {
      artistName: data.artistName,
      email: data.email,
      lastTrack: data.lastTrack,
    };
  } catch (error) {
    console.error('Failed to retrieve submission data:', error);
    return null;
  }
}

export function resetAllStorageData(): void {
  try {
    localStorage.removeItem(STORAGE_KEY);
    localStorage.removeItem('trackSubmissions');
    localStorage.removeItem('playlist_submissions');
    localStorage.removeItem('spotify_token');
    sessionStorage.clear();
  } catch (error) {
    console.error('Failed to reset storage data:', error);
  }
}