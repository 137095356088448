import React from 'react';
import { Link } from 'react-router-dom';
import { Music2, ArrowLeft } from 'lucide-react';
import { useGenres } from '../hooks/useGenres';
import { slugify } from '../utils/formatting';
import { SEO } from '../components/SEO';

export function GenreIndex() {
  const { genres, loading } = useGenres();

  return (
    <>
      <SEO 
        title="Browse Spotify Playlist Genres - Free Music Submission | PlaylistPartner"
        description="Discover Spotify playlists in every genre and sub-genre. Submit your music directly to curators for free - from hip-hop to classical, find the perfect playlists for your sound."
        image="https://ftwlzdhkzqfuosdorgjt.supabase.co/storage/v1/object/public/playlistpartner%20public/playlistpartner%20og%20image.png"
        url={`${window.location.origin}/genres`}
        type="website"
      />

      <main className="container mx-auto px-4 py-8 max-w-5xl">
        <Link 
          to="/"
          className="inline-flex items-center gap-2 text-green-600 hover:text-green-700 mb-6"
        >
          <ArrowLeft className="w-4 h-4" />
          Back to Home
        </Link>

        <div className="bg-white rounded-lg shadow-sm p-6 mb-8">
          <h1 className="text-3xl font-bold text-gray-900 mb-2">Spotify Playlist Genres</h1>
          <p className="text-gray-600">
            Discover Spotify playlists in every genre and sub-genre. Submit your music directly to curators for free - from hip-hop to classical, find the perfect playlists for your sound.
          </p>
        </div>

        {loading ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {[...Array(6)].map((_, i) => (
              <div key={i} className="bg-white p-6 rounded-lg shadow-sm animate-pulse">
                <div className="h-6 w-1/3 bg-gray-200 rounded mb-4"></div>
                <div className="space-y-2">
                  {[...Array(4)].map((_, j) => (
                    <div key={j} className="h-4 bg-gray-100 rounded w-2/3"></div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {genres.map(genre => (
              <div key={genre.id} className="bg-white p-6 rounded-lg shadow-sm">
                <Link
                  to={`/genre/${slugify(genre.name)}`}
                  className="group flex items-center gap-3 mb-4"
                >
                  <div className="p-2 bg-gray-50 rounded-lg group-hover:bg-green-50 transition-colors">
                    <Music2 className="w-5 h-5 text-gray-500 group-hover:text-green-600" />
                  </div>
                  <h2 className="text-xl font-semibold text-gray-900 group-hover:text-green-600 transition-colors">
                    {genre.name}
                  </h2>
                </Link>

                {genre.subGenres.length > 0 && (
                  <div className="space-y-1">
                    {genre.subGenres.map(subGenre => (
                      <Link
                        key={subGenre.id}
                        to={`/genre/${slugify(genre.name)}/${slugify(subGenre.name)}`}
                        className="block text-gray-600 hover:text-green-600 transition-colors pl-11"
                      >
                        {subGenre.name}
                        {subGenre.description && (
                          <p className="text-sm text-gray-500 mt-0.5">{subGenre.description}</p>
                        )}
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </main>
    </>
  );
}