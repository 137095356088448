import React, { useState, useEffect } from 'react';
import { X, Music2, Link, User, Mail, MessageSquare, AlertCircle, Loader2 } from 'lucide-react';
import type { Playlist, TrackSubmission } from '../types';
import { checkSubmissionLimit, recordSpotifySubmission, SUBMISSION_LIMIT } from '../utils/rateLimit';
import { saveSubmissionData, getLastSubmissionData } from '../utils/storage';
import { trackSubmission } from '../utils/submissionTracking';
import { Toast } from './Toast';
import { TrackSearch } from './TrackSearch';
import type { SpotifyTrack } from '../utils/spotify';
import { submissionsService } from '../services/submissions.service';
import { extractSpotifyId } from '../utils/spotify';

interface SubmissionModalProps {
  playlist: Playlist;
  onClose: () => void;
  onSubmit: (submission: TrackSubmission) => void;
}

export function SubmissionModal({ playlist, onClose, onSubmit }: SubmissionModalProps) {
  const [formData, setFormData] = useState<TrackSubmission>(() => {
    const savedData = getLastSubmissionData();
    return {
      spotifyUrl: savedData?.lastTrack?.external_urls.spotify || '',
      artistName: savedData?.artistName || '',
      email: savedData?.email || '',
      message: ''
    };
  });

  const [selectedTrack, setSelectedTrack] = useState<SpotifyTrack | null>(() => {
    const savedData = getLastSubmissionData();
    return savedData?.lastTrack || null;
  });

  const [errors, setErrors] = useState<Partial<TrackSubmission & { submit?: string }>>({});
  const [rateLimitInfo, setRateLimitInfo] = useState<{
    allowed: boolean;
    remainingSubmissions: number;
    timeUntilReset: number | null;
    totalSubmissions: number;
    alreadySubmittedToPlaylist: boolean;
  }>({ 
    allowed: true, 
    remainingSubmissions: SUBMISSION_LIMIT, 
    timeUntilReset: null, 
    totalSubmissions: 0,
    alreadySubmittedToPlaylist: false
  });

  const [timeLeft, setTimeLeft] = useState<string>('');

  // Handle click outside modal
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      const modalContent = document.querySelector('.modal-content');
      if (modalContent && !modalContent.contains(e.target as Node)) {
        onClose();
      }
    };

    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleEscape);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscape);
    };
  }, [onClose]);

  useEffect(() => {
    if (formData.spotifyUrl) {
      const limit = checkSubmissionLimit(formData.spotifyUrl, playlist.id);
      setRateLimitInfo(limit);
    }
  }, [formData.spotifyUrl, playlist.id]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    
    const updateTimeLeft = () => {
      if (rateLimitInfo.timeUntilReset) {
        const hours = Math.floor(rateLimitInfo.timeUntilReset / (1000 * 60 * 60));
        const minutes = Math.floor((rateLimitInfo.timeUntilReset % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((rateLimitInfo.timeUntilReset % (1000 * 60)) / 1000);
        setTimeLeft(`${hours}h ${minutes}m ${seconds}s`);
      }
    };

    if (rateLimitInfo.timeUntilReset) {
      updateTimeLeft();
      timer = setInterval(() => {
        const newTimeUntilReset = rateLimitInfo.timeUntilReset! - 1000;
        if (newTimeUntilReset <= 0) {
          clearInterval(timer);
          setRateLimitInfo(prev => ({ ...prev, timeUntilReset: null, allowed: true }));
        } else {
          setRateLimitInfo(prev => ({ ...prev, timeUntilReset: newTimeUntilReset }));
          updateTimeLeft();
        }
      }, 1000);
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [rateLimitInfo.timeUntilReset]);

  const validateForm = () => {
    const newErrors: Partial<TrackSubmission> = {};
    
    if (!selectedTrack) {
      newErrors.spotifyUrl = 'Please select a track';
    }
    if (!formData.artistName.trim()) {
      newErrors.artistName = 'Artist name is required';
    }
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = 'Please enter a valid email address';
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!rateLimitInfo.allowed || rateLimitInfo.alreadySubmittedToPlaylist) return;
    
    if (validateForm()) {
      try {
        const trackId = extractSpotifyId(formData.spotifyUrl, 'track');
        if (!trackId || !selectedTrack) {
          throw new Error('Invalid track selection');
        }

        await submissionsService.submit({
          playlistId: playlist.id,
          trackId,
          trackName: selectedTrack.name,
          trackUrl: formData.spotifyUrl,
          artistName: formData.artistName,
          email: formData.email,
          message: formData.message
        });

        saveSubmissionData({
          artistName: formData.artistName,
          email: formData.email,
          track: selectedTrack
        });
        
        recordSpotifySubmission(formData.spotifyUrl, playlist.id);
        trackSubmission(playlist.id, formData.spotifyUrl);
        
        onSubmit(formData);
      } catch (error) {
        console.error('Failed to submit track:', error);
        setErrors(prev => ({
          ...prev,
          submit: 'Failed to submit track. Please try again.'
        }));
      }
    }
  };

  const handleTrackSelect = (track: SpotifyTrack) => {
    setSelectedTrack(track);
    setFormData(prev => ({
      ...prev,
      spotifyUrl: track.external_urls.spotify,
      artistName: track.artists[0]?.name || prev.artistName
    }));
    setErrors(prev => ({ ...prev, spotifyUrl: undefined }));
  };

  const handleClearTrack = () => {
    setSelectedTrack(null);
    setFormData(prev => ({
      ...prev,
      spotifyUrl: '',
      artistName: prev.artistName
    }));
    setErrors(prev => ({ ...prev, spotifyUrl: undefined }));
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="modal-content bg-white rounded-xl max-w-lg w-full max-h-[90vh] overflow-y-auto">
        <div className="p-6 border-b border-gray-200">
          <div className="flex justify-between items-center">
            <h2 className="text-xl font-bold text-gray-900">Submit Your Track</h2>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-500 transition-colors"
            >
              <X className="w-6 h-6" />
            </button>
          </div>
          <p className="mt-2 text-gray-600">
            Submitting to: {playlist.name}
          </p>
          {selectedTrack && (
            <div className="mt-3 flex flex-col gap-2 text-sm">
              <div className={`px-3 py-2 rounded-lg ${
                rateLimitInfo.alreadySubmittedToPlaylist
                  ? 'bg-yellow-100 text-yellow-800'
                  : rateLimitInfo.allowed
                    ? 'bg-green-100 text-green-800'
                    : 'bg-red-100 text-red-800'
              }`}>
                {rateLimitInfo.alreadySubmittedToPlaylist ? (
                  <div className="flex items-center gap-1">
                    <AlertCircle className="w-4 h-4" />
                    Already submitted to this playlist
                  </div>
                ) : rateLimitInfo.allowed ? (
                  <span>
                    {rateLimitInfo.remainingSubmissions}/{SUBMISSION_LIMIT} Submissions Remaining Today
                  </span>
                ) : (
                  <>
                    <div className="flex items-center gap-1">
                      <AlertCircle className="w-4 h-4" />
                      Each track can be submitted to up to 10 playlists per day.
                    </div>
                    {timeLeft && (
                      <div className="text-sm mt-1">
                        (Resets in {timeLeft})
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          )}
        </div>

        <form onSubmit={handleSubmit} className="p-6 space-y-4">
          <div>
            <label className="flex gap-2 text-sm font-medium text-gray-700 mb-1">
              <Link className="w-4 h-4" />
              Search and Select Your Track
            </label>
            <TrackSearch 
              onTrackSelect={handleTrackSelect}
              selectedTrack={selectedTrack}
              onClearTrack={handleClearTrack}
            />
            {errors.spotifyUrl && (
              <p className="mt-1 text-sm text-red-600">{errors.spotifyUrl}</p>
            )}
          </div>

          <div>
            <label className="flex gap-2 text-sm font-medium text-gray-700 mb-1">
              <User className="w-4 h-4" />
              Artist Name
            </label>
            <input
              type="text"
              placeholder="Your artist name"
              value={formData.artistName}
              onChange={(e) => setFormData(prev => ({ ...prev, artistName: e.target.value }))}
              className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent"
              disabled={!rateLimitInfo.allowed || rateLimitInfo.alreadySubmittedToPlaylist}
            />
            {errors.artistName && (
              <p className="mt-1 text-sm text-red-600">{errors.artistName}</p>
            )}
          </div>

          <div>
            <label className="flex gap-2 text-sm font-medium text-gray-700 mb-1">
              <Mail className="w-4 h-4" />
              Email
            </label>
            <input
              type="email"
              placeholder="your@email.com"
              value={formData.email}
              onChange={(e) => setFormData(prev => ({ ...prev, email: e.target.value }))}
              className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent"
              disabled={!rateLimitInfo.allowed || rateLimitInfo.alreadySubmittedToPlaylist}
            />
            {errors.email && (
              <p className="mt-1 text-sm text-red-600">{errors.email}</p>
            )}
          </div>

          <div>
            <label className="flex gap-2 text-sm font-medium text-gray-700 mb-1">
              <MessageSquare className="w-4 h-4" />
              Message (Optional)
            </label>
            <textarea
              placeholder="Tell the curator about your track..."
              value={formData.message}
              onChange={(e) => setFormData(prev => ({ ...prev, message: e.target.value }))}
              rows={4}
              className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent"
              disabled={!rateLimitInfo.allowed || rateLimitInfo.alreadySubmittedToPlaylist}
            />
          </div>

          {errors.submit && (
            <div className="flex items-center gap-2 text-red-600 text-sm">
              <AlertCircle className="w-4 h-4" />
              <p>{errors.submit}</p>
            </div>
          )}

          <div className="flex justify-end gap-3 pt-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg transition-colors"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={!rateLimitInfo.allowed || rateLimitInfo.alreadySubmittedToPlaylist}
              className={`px-4 py-2 rounded-lg flex items-center gap-2 transition-colors ${
                !rateLimitInfo.allowed || rateLimitInfo.alreadySubmittedToPlaylist
                  ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                  : 'bg-green-500 text-white hover:bg-green-600'
              }`}
            >
              <Music2 className="w-4 h-4" />
              Submit Track
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}