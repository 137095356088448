import { supabase } from '../lib/supabase';
import type { Database } from '../services/database.types';

type Submission = Database['public']['Tables']['submissions']['Row'] & {
  playlist?: {
    name: string;
  };
};

export const submissionsService = {
  async submit(data: {
    playlistId: string;
    trackId: string;
    trackName: string;
    trackUrl: string;
    artistName: string;
    email: string;
    message?: string;
  }): Promise<Submission> {
    const { data: submission, error } = await supabase
      .from('submissions')
      .insert({
        playlist_id: data.playlistId,
        track_id: data.trackId,
        track_name: data.trackName,
        track_url: data.trackUrl,
        artist_name: data.artistName,
        email: data.email,
        message: data.message,
        status: 'pending',
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString()
      })
      .select(`
        *,
        playlist:playlists (
          name
        )
      `)
      .single();

    if (error) {
      console.error('Failed to create submission:', error);
      throw error;
    }

    return submission;
  },

  async getAll() {
    const { data, error } = await supabase
      .from('submission_details')
      .select('*')
      .order('created_at', { ascending: false });

    if (error) {
      console.error('Failed to fetch submissions:', error);
      throw error;
    }

    return data.map(submission => ({
      ...submission,
      playlist: {
        name: submission.playlist_name
      }
    }));
  },

  async getHistory(email: string): Promise<Submission[]> {
    const { data, error } = await supabase
      .from('submission_details')
      .select('*')
      .eq('email', email)
      .order('created_at', { ascending: false });

    if (error) {
      console.error('Failed to fetch submission history:', error);
      throw error;
    }

    return data.map(submission => ({
      ...submission,
      playlist: {
        name: submission.playlist_name
      }
    }));
  },

  async getForPlaylist(playlistId: string): Promise<Submission[]> {
    const { data, error } = await supabase
      .from('submission_details')
      .select('*')
      .eq('playlist_id', playlistId)
      .order('created_at', { ascending: false });

    if (error) {
      console.error('Failed to fetch playlist submissions:', error);
      throw error;
    }

    return data.map(submission => ({
      ...submission,
      playlist: {
        name: submission.playlist_name
      }
    }));
  },

  async updateStatus(id: string, status: 'approved' | 'rejected' | 'pending'): Promise<Submission> {
    const { data, error } = await supabase
      .from('submissions')
      .update({
        status,
        reviewed_at: status === 'pending' ? null : new Date().toISOString(),
        updated_at: new Date().toISOString()
      })
      .eq('id', id)
      .select(`
        *,
        playlist:playlists (
          name
        )
      `)
      .single();

    if (error) {
      console.error('Failed to update submission status:', error);
      throw error;
    }

    return data;
  }
};