export const SUBMISSION_LIMIT = 10;
const SUBMISSION_WINDOW = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

interface SubmissionRecord {
  timestamp: number;
  spotifyUrl: string;
  playlistId: string;
}

function normalizeSpotifyUrl(url: string): string {
  const trackId = extractSpotifyId(url, 'track');
  return trackId || url;
}

export function checkSubmissionLimit(spotifyUrl: string, playlistId: string): {
  allowed: boolean;
  remainingSubmissions: number;
  timeUntilReset: number | null;
  totalSubmissions: number;
  alreadySubmittedToPlaylist: boolean;
} {
  try {
    // Ensure required parameters are provided
    if (!spotifyUrl || !playlistId) {
      return {
        allowed: false,
        remainingSubmissions: 0,
        timeUntilReset: null,
        totalSubmissions: 0,
        alreadySubmittedToPlaylist: false
      };
    }

    const submissions: SubmissionRecord[] = JSON.parse(
      localStorage.getItem('trackSubmissions') || '[]'
    );

    const now = Date.now();
    const normalizedUrl = normalizeSpotifyUrl(spotifyUrl);
    
    // Filter for recent submissions and clean up old ones
    const recentSubmissions = submissions.filter(
      sub => now - sub.timestamp < SUBMISSION_WINDOW
    );

    if (recentSubmissions.length < submissions.length) {
      localStorage.setItem('trackSubmissions', JSON.stringify(recentSubmissions));
    }

    // Check if track was already submitted to this playlist
    const playlistSubmission = recentSubmissions.find(
      sub => normalizeSpotifyUrl(sub.spotifyUrl) === normalizedUrl && 
            sub.playlistId === playlistId
    );

    if (playlistSubmission) {
      const timeUntilReset = Math.max(0, SUBMISSION_WINDOW - (now - playlistSubmission.timestamp));
      return {
        allowed: false,
        remainingSubmissions: 0,
        timeUntilReset,
        totalSubmissions: recentSubmissions.length,
        alreadySubmittedToPlaylist: true
      };
    }

    // Check total submissions for this track
    const trackSubmissions = recentSubmissions.filter(
      sub => normalizeSpotifyUrl(sub.spotifyUrl) === normalizedUrl
    );

    if (trackSubmissions.length >= SUBMISSION_LIMIT) {
      // Find oldest submission to calculate reset time
      const oldestSubmission = trackSubmissions.reduce((oldest, current) => 
        current.timestamp < oldest.timestamp ? current : oldest
      );
      
      const timeUntilReset = Math.max(0, SUBMISSION_WINDOW - (now - oldestSubmission.timestamp));
      
      return {
        allowed: false,
        remainingSubmissions: 0,
        timeUntilReset,
        totalSubmissions: trackSubmissions.length,
        alreadySubmittedToPlaylist: false
      };
    }

    return {
      allowed: true,
      remainingSubmissions: SUBMISSION_LIMIT - trackSubmissions.length,
      timeUntilReset: null,
      totalSubmissions: trackSubmissions.length,
      alreadySubmittedToPlaylist: false
    };
  } catch (error) {
    console.error('Rate limit check failed:', error);
    // Return a safe default state if something goes wrong
    return { 
      allowed: false, 
      remainingSubmissions: 0, 
      timeUntilReset: null,
      totalSubmissions: 0,
      alreadySubmittedToPlaylist: false
    };
  }
}

export function recordSpotifySubmission(spotifyUrl: string, playlistId: string): void {
  if (!spotifyUrl || !playlistId) return;

  try {
    const submissions: SubmissionRecord[] = JSON.parse(
      localStorage.getItem('trackSubmissions') || '[]'
    );
    
    submissions.push({
      timestamp: Date.now(),
      spotifyUrl: normalizeSpotifyUrl(spotifyUrl),
      playlistId
    });

    localStorage.setItem('trackSubmissions', JSON.stringify(submissions));
  } catch (error) {
    console.error('Failed to record submission:', error);
  }
}

export function extractSpotifyId(url: string, type: 'track' | 'playlist'): string | null {
  if (!url) return null;
  
  try {
    // Handle both URL and URI formats
    const patterns = [
      new RegExp(`${type}/([a-zA-Z0-9]+)`), // URL format
      new RegExp(`${type}:([a-zA-Z0-9]+)`)   // URI format
    ];

    for (const pattern of patterns) {
      const match = url.match(pattern);
      if (match && match[1]) {
        return match[1];
      }
    }

    return null;
  } catch (error) {
    console.error('Failed to extract ID:', error);
    return null;
  }
}