import React from 'react';
import { Link } from 'react-router-dom';
import { Music2, ListMusic } from 'lucide-react';
import { useGenres } from '../hooks/useGenres';
import { slugify } from '../utils/formatting';

interface GenreMegaMenuProps {
  onClose: () => void;
}

export function GenreMegaMenu({ onClose }: GenreMegaMenuProps) {
  const { genres, loading } = useGenres();

  if (loading) {
    return (
      <div className="p-6">
        <div className="grid grid-cols-3 gap-4">
          {[...Array(6)].map((_, i) => (
            <div key={`skeleton-${i}`} className="h-12 bg-gray-100 rounded-lg animate-pulse" />
          ))}
        </div>
      </div>
    );
  }

  // Group genres into columns
  const columns = genres.reduce((acc, genre, i) => {
    const columnIndex = Math.floor(i / Math.ceil(genres.length / 3));
    if (!acc[columnIndex]) acc[columnIndex] = [];
    acc[columnIndex].push(genre);
    return acc;
  }, [] as typeof genres[]);

  return (
    <div className="p-6">
      <Link
        to="/genres"
        onClick={onClose}
        className="flex items-center gap-2 p-3 mb-4 text-gray-600 hover:text-green-600 hover:bg-gray-50 rounded-lg transition-colors"
      >
        <ListMusic className="w-5 h-5" />
        <span className="font-medium">View All Genres & Sub-genres</span>
      </Link>

      <div className="grid grid-cols-3 gap-6">
        {columns.map((columnGenres, colIndex) => (
          <div key={colIndex} className="space-y-1">
            {columnGenres.map(genre => (
              <Link
                key={genre.id}
                to={`/genre/${slugify(genre.name)}`}
                onClick={onClose}
                className="flex items-center gap-2 p-3 rounded-lg hover:bg-gray-50 text-gray-900 group"
              >
                <div className="p-1.5 bg-gray-50 rounded group-hover:bg-green-50 transition-colors">
                  <Music2 className="w-4 h-4 text-gray-500 group-hover:text-green-600" />
                </div>
                <span className="font-medium">{genre.name}</span>
              </Link>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}