import React from 'react';
import { Music4, Heart, Zap, Users } from 'lucide-react';
import { SEO } from '../components/SEO';

export function AboutPage() {
  return (
    <>
      <SEO 
        title="About PlaylistPartner - Free Spotify Playlist Submission Platform"
        description="Learn about PlaylistPartner, your free platform for submitting music to Spotify playlist curators. No signup required, direct curator connections."
      />

      <div className="bg-gradient-to-b from-green-50 to-white">
        <div className="container mx-auto px-4 py-16 md:py-24">
          {/* Hero */}
          <div className="max-w-3xl mx-auto text-center mb-16">
            <Music4 className="w-12 h-12 text-green-600 mx-auto mb-6" />
            <h1 className="text-4xl font-bold text-gray-900 mb-6">
              Music Promotion Made Simple
            </h1>
            <p className="text-xl text-gray-600">
              Submit your music directly to Spotify playlist curators without the typical barriers. No signups, no fees, and no waiting around. Only on PlaylistPartner.
            </p>
          </div>

          {/* Key Features */}
          <div className="max-w-5xl mx-auto grid md:grid-cols-3 gap-8 mb-24">
            <div className="bg-white p-6 rounded-lg shadow-sm text-center">
              <Heart className="w-8 h-8 text-green-600 mx-auto mb-4" />
              <h3 className="text-lg font-semibold mb-2">Always Free</h3>
              <p className="text-gray-600">
                No hidden fees or premium features. Submit your music to 10 playlists daily – it's that simple.
              </p>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-sm text-center">
              <Zap className="w-8 h-8 text-green-600 mx-auto mb-4" />
              <h3 className="text-lg font-semibold mb-2">No Sign Up</h3>
              <p className="text-gray-600">
                Start submitting your music immediately. No account creation required.
              </p>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-sm text-center">
              <Users className="w-8 h-8 text-green-600 mx-auto mb-4" />
              <h3 className="text-lg font-semibold mb-2">Direct Connection</h3>
              <p className="text-gray-600">
                Connect directly with curators in your genre and style. Filter playlists by genre, sub-genre, or search.
              </p>
            </div>
          </div>

          {/* How It Works */}
          <div className="max-w-4xl mx-auto mb-24">
            <h2 className="text-3xl font-bold text-center mb-12">How It Works</h2>
            <div className="space-y-12">
              <div className="flex items-start gap-6">
                <div className="w-10 h-10 rounded-full bg-green-100 flex-shrink-0 flex items-center justify-center">
                  <span className="text-green-600 font-bold">1</span>
                </div>
                <div>
                  <h3 className="text-xl font-semibold mb-2">Find Your Playlists</h3>
                  <p className="text-gray-600">
                    Browse our curated collection of Spotify playlists by genre, sub genre or follower count.
                  </p>
                </div>
              </div>

              <div className="flex items-start gap-6">
                <div className="w-10 h-10 rounded-full bg-green-100 flex-shrink-0 flex items-center justify-center">
                  <span className="text-green-600 font-bold">2</span>
                </div>
                <div>
                  <h3 className="text-xl font-semibold mb-2">Submit Your Track</h3>
                  <p className="text-gray-600">
                    Select your track from Spotify and submit it to any playlist that matches your style. 
                    Add a personal message to the curator to increase your chances.
                  </p>
                </div>
              </div>

              <div className="flex items-start gap-6">
                <div className="w-10 h-10 rounded-full bg-green-100 flex-shrink-0 flex items-center justify-center">
                  <span className="text-green-600 font-bold">3</span>
                </div>
                <div>
                  <h3 className="text-xl font-semibold mb-2">Get Featured</h3>
                  <p className="text-gray-600">
                    Curators review your submission and add tracks that fit their playlist. 
                    Build relationships with curators and grow your audience organically.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Stats */}
          <div className="max-w-4xl mx-auto border-t border-gray-200 pt-16">
            <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
              <div className="text-center">
                <div className="text-3xl font-bold text-green-600 mb-1">4000+</div>
                <div className="text-sm text-gray-600">Active Playlists</div>
              </div>
              <div className="text-center">
                <div className="text-3xl font-bold text-green-600 mb-1">15K+</div>
                <div className="text-sm text-gray-600">Monthly Submissions</div>
              </div>
              <div className="text-center">
                <div className="text-3xl font-bold text-green-600 mb-1">2000+</div>
                <div className="text-sm text-gray-600">Curators</div>
              </div>
              <div className="text-center">
                <div className="text-3xl font-bold text-green-600 mb-1">100%</div>
                <div className="text-sm text-gray-600">Free</div>
              </div>
            </div>
          </div>

          {/* CTA */}
          <div className="max-w-xl mx-auto text-center mt-24">
            <h2 className="text-2xl font-bold mb-6">Ready to Share Your Music?</h2>
            <div className="flex flex-col sm:flex-row gap-4 justify-center">
              <a 
                href="/submit" 
                className="inline-flex items-center justify-center bg-green-500 text-white px-8 py-3 rounded-lg hover:bg-green-600 transition-colors font-medium"
              >
                Submit Your Music
              </a>
              <a 
                href="/" 
                className="inline-flex items-center justify-center bg-white text-gray-700 px-8 py-3 rounded-lg hover:bg-gray-100 transition-colors font-medium border border-gray-200"
              >
                Browse Playlists
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}