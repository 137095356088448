import { useState, useEffect } from 'react';
import { supabase } from '../lib/supabase';

export function useGenres() {
  const [genres, setGenres] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    async function fetchGenres() {
      try {
        setLoading(true);
        setError(null);

        // Fetch genres
        const { data: genresData, error: genresError } = await supabase
          .from('genres')
          .select('*')
          .order('display_order');

        if (genresError) throw genresError;

        // Fetch sub-genres
        const { data: subGenresData, error: subGenresError } = await supabase
          .from('sub_genres')
          .select('*')
          .order('name');

        if (subGenresError) throw subGenresError;

        // Map sub-genres to their parent genres
        const processedGenres = genresData.map(genre => ({
          ...genre,
          subGenres: subGenresData
            .filter(sg => sg.genre_id === genre.id)
            .map(sg => ({
              id: sg.id,
              name: sg.name,
              description: sg.description
            }))
        }));

        setGenres(processedGenres);
      } catch (err) {
        console.error('Failed to fetch genres:', err);
        setError(err instanceof Error ? err : new Error('Failed to fetch genres'));
      } finally {
        setLoading(false);
      }
    }

    fetchGenres();
  }, []);

  return { genres, loading, error };
}